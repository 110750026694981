import { Radio, RadioGroup, Stack, FormControl, HStack, Text} from '@chakra-ui/react';

const PieSelector = ({ value, darkMode, onChange, options = [] }) => {
    return (
        <FormControl as='fieldset' m="1">
            <RadioGroup value={value} onChange={onChange}>
                <HStack spacing='24px'>
                {options.map(option => (
                    <Radio
                        size = 'sm'
                        key={option.value} // Changed from 'fontkey' to 'key' as it seems to be a typo
                        value={option.value}
                        colorScheme={darkMode ? "whiteAlpha" : "gray"}// Default color scheme in light mode
                        borderColor= {darkMode ? '#505050' : 'inherit'}
                        _checked={{
                            bg: darkMode ? 'white' : 'white',
                            borderColor: darkMode ? '#454c5e' : 'black',
                            color: darkMode ? '#454c5e' : 'black',
                            border: '4px solid',
                          }}
                        sx={{
                            '.chakra-radio__label': {
                              fontSize: '14px',
                              color: darkMode ? 'white' : 'black',
                              marginLeft: 2,
                            },
                          }}
                    >
                        <Text color={darkMode ? 'white' : 'black'} fontSize="14px">{option.label}</Text>    
                    </Radio>
                ))}
                </HStack>
            </RadioGroup>
        </FormControl>
    );
};

export default PieSelector;