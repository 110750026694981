import React, { useState, useEffect } from "react";

const StockPieChart = React.memo(({ percentage, label, darkMode }) => {
  const [currentPercentage, setCurrentPercentage] = useState(0);
  const isNumeric = !isNaN(percentage) && isFinite(percentage);
  const parsedPercentage = parseFloat(percentage);
  const parsedLabel = parseFloat(label);
  useEffect(() => {
    if (isNumeric) {
      if (parsedPercentage === 0) {
        setCurrentPercentage(0);
        return;
      }
      let start = 0;
      const end = Math.abs(parsedPercentage);
      const interval = setInterval(() => {
        if (start < end) {
          start++;
          setCurrentPercentage(start);
        } else {
          clearInterval(interval);
          setCurrentPercentage(Math.abs(Math.round(parsedPercentage)));
        }
      }, 20);

      return () => clearInterval(interval);
    } else {
      setCurrentPercentage(0);
    }
  }, [percentage, isNumeric, parsedPercentage]);

  const fillColor = isNumeric ? 
                    (parsedPercentage >= 0 ? "#00A36C" : "#FF2400") : 
                    (darkMode ? "#505050" : "#000000");
  const fillDegree = isNumeric ? (Math.abs(currentPercentage) / 100) * 360 : 360;

  const displayPercentage = isNumeric
    ? `${parsedPercentage < 0 ? `-${currentPercentage}` : currentPercentage}%`
    : percentage;
  // Updated pieChartTextStyle to also consider non-numeric 'percentage' for color adjustment
  const pieChartTextStyle = {
    fontSize: "inherit",
    color: (!isNumeric || parsedPercentage === 0) 
    ? (darkMode ? '#FFFFFF' : 'black')  : parsedPercentage > 0 ? '#00A36C' : '#FF2400',
  };

  let labelStyle = {};
  let labelText = `${label}%`;

  if (parsedLabel > 0) {
    labelStyle = { color: '#00A36C' }; // Green for positive values
    labelText = `${label}%`; // Including '+' sign for positive values as per requirement
  } else if (parsedLabel < 0) {
    labelStyle = { color: '#FF2400' }; // Red for negative values
  } else {
    labelStyle = { marginLeft: '0.07em', color: 'black' }; // Black color for 0 with margin
  }

  return (
    <div className={`pie-chart-container ${darkMode ? 'dark-mode' : ''}`}>
      <div
        className="pie-chart"
        style={{
          "--percentage": `${fillDegree}deg`,
          "--fill-color": fillColor,
        }}
      >
        <div className="pie-chart-text" style={pieChartTextStyle}>{displayPercentage}</div>
      </div>
      {isNumeric && (
        <div className="change">
          <span style={{ color: darkMode ? '#FFFFFF' : 'inherit' }}>
        Change
      </span>
          <span style={labelStyle}>
            {labelText}
          </span>
        </div>
      )}
    </div>
  );
});

export default StockPieChart;