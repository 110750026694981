import React, { useEffect, useRef, useState } from "react";
import * as d3 from "d3";
import "./D3PieChart.css";

const D3PieChart = ({ value1, selectedBuzzOption, darkMode }) => {
  const ref = useRef();
  const greyColor = '#cccccc';
  const defaultColor = '#2CAFFE';
  const [svgSize, setSvgSize] = useState({ width: 192, height: 192 });
  const hoverIncreaseFactor = 1.1;  // Increase size by 10% on hover
  const darkGrey = '#505050'; // Darker grey for dark mode when value is 0  

  useEffect(() => {
    const baseSize = 192;

    const radius = Math.min(svgSize.width, svgSize.height) / 2.5;
    const donutWidth = 50;
    const hoverRadius = radius * 1.03;
  
    d3.select(ref.current).select("svg").remove();

    const svg = d3.select(ref.current)
      .append("svg")
      .attr("width", svgSize.width)
      .attr("height", svgSize.height)
      .append("g")
      .attr("transform", `translate(${svgSize.width / 2}, ${svgSize.height / 2})`);

      let pieData = [
        { 
          label: "Buzz Score", 
          value: value1 === 0 ? 1 : value1, 
          color: value1 === 0 ? (darkMode ? darkGrey : greyColor) : defaultColor 
        }
      ];


    const pie = d3.pie()
      .sort(null)
      .value(d => d.value);

    const path = d3.arc()
      .outerRadius(radius)
      .innerRadius(radius - donutWidth);

    const arc = svg.selectAll(".arc")
      .data(pie(pieData))
      .enter().append("g")
      .attr("class", "arc");

      arc.style('pointer-events', 'none');

      // Apply the transition to the arcs
      arc.append("path")
        .attr("d", path)
        .attr("fill", (d) => d.data.color)
        .attr("stroke", "none")
        .attr("stroke-width", "2px")
        .transition()
        .duration(1000)
        .attrTween('d', function(d) {
          const interpolate = d3.interpolate({ startAngle: 0, endAngle: 0 }, d);
          return function(t) {
            return path(interpolate(t));
          };
        })
        .on('end', function() {
          // Re-enable pointer events after the transition is done
          d3.select(this.parentNode).style('pointer-events', 'auto');
        });
        

    // Append text conditionally
    if (value1 !== 0) {
      arc.append("text")
        .attr("transform", d => `translate(${path.centroid(d)})`)
        .attr("text-anchor", "middle")
        .style("fill", "white")
        .style("font-size", "16px")
        .style("font-weight", "bold") // Make the font bolder
        .text(d => d.data.value);
    } else {
      // Add a '0' in the middle of the donut if the value is 0
      svg.append("text")
        .attr("text-anchor", "middle")
        .attr("dy", "5px")
        .style("fill", darkMode ? "white" : "black")
        .style("font-size", "20px")
        .text("0");
    }

       const tooltip = d3.select("body").append("div")
       .attr("class", "tooltip") // Use a class to style the tooltip
       .style("position", "absolute")
       .style("display", "none")  // Use display none for initial state
       .style("background", "white")
       .style("border", "1px solid #ccc")
       .style("border-radius", "5px")
       .style("padding", "5px")
       .style("box-shadow", "2px 2px 5px rgba(0,0,0,0.2)")
       .style("pointer-events", "none");
 
       arc.on("mouseover", function(event, d) {
          setSvgSize({ width: baseSize * 1.1, height: baseSize * 1.1 });
          d3.select(this).select("path")
            .transition()
            .duration(200) // Duration of the animation in milliseconds
            .attr("d", path.outerRadius(hoverRadius)); // Apply the larger radius
        
            const valueToShow = value1 === 0 ? 0 : d.data.value; // Show 0 if value1 is 0
            
            tooltip.html(`<div><span style="height: 10px; width: 10px; background-color: ${d.data.color}; border-radius: 50%; display: inline-block;"></span> ${d.data.label}: ${valueToShow}</div>`)
            .style("display", "block")  // Change to display block to show
              .style("top", (event.pageY - 10) + "px")
            .style("left", (event.pageX + 10) + "px");
        
        
          // Shrink the other arc
          arc.filter((e) => e.data.label !== d.data.label).select("path")
            .transition()
            .duration(200) // Duration of the animation in milliseconds
            .attr("d", path.outerRadius(radius * 0.9))

      })
      .on("mousemove", function(event, d) {
        tooltip.style("top", (event.pageY - 10) + "px")
          .style("left", (event.pageX + 10) + "px");
      })
      .on("mouseout", function(event, d) {
        setSvgSize({ width: baseSize, height: baseSize });
        // Reset the arc size back to its original radius
        d3.select(this).select("path")
          .transition()
          .duration(200)
          .attr("d", path.outerRadius(radius));
      
        // Reset the size of the other arcs to their original radius as well
        arc.filter((e) => e.data.label !== d.data.label).select("path")
          .transition()
          .duration(200)
          .attr("d", path.outerRadius(radius));
      
        // Hide the tooltip
        tooltip.style("display", "none");  // Hide by switching to display none
      });
    // Remove any previous legends
    svg.selectAll(".legend").remove();
  }, [value1, selectedBuzzOption, darkMode]);

  return <div ref={
ref} />;
};
export default D3PieChart;