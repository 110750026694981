import { Flex, Stack, Box, Text, Image, Link } from "@chakra-ui/react";
import React from 'react';
import logo from "../assets/White_1 logo - no background.png";
import './LoginBranding.css';

const animatedWords = [
  "Amplified", "Bettered", "Augmented", "Boosted", 
  "Deepened", "Elevated", "Fine-Tuned", "Expanded"
];

const LoginBranding = () => {
  // Use CSS custom properties for better manageability and readability
  // This approach helps in ensuring consistency and easier adjustments
  const fontSizeBase = {
    min: '2.35rem',
    preferred: '2.01vw',
    max: '4rem',
  };

  const altFontSizeBase = {
    min: '2.0rem',
    preferred: '1.6vw',
    max: '4rem',
  };

  return (
    <Flex
      flex={1}
      flexDirection="column"
      width="100vw" // Use vw for viewport width instead of "wh"
      height="100vh"
      justifyContent="center"
      alignItems="center"
      bg="black"
    >
      <Stack
        flexDir="column"
        height="100vh"
        mb="2"
        justifyContent="space-between"
        alignItems="left"
      >
        <Box h="3px"/>
          <Box sx={{
          maxWidth: '600px',
          marginX: 'auto',
          textAlign: 'left',
        }}>
          <Link href="https://www.nlpify.ai" sx={{ cursor: 'pointer' }}>
            <Image 
              src={logo}
              alt='NLPify Logo' 
              sx={{
                width: ['15vw', '20vw', '30vw', '50vw'],
                maxWidth: '600px',
                height: 'auto',
              }}
            />
          </Link>
          <Flex
            fontFamily="Noto Sans" // Ensure font is correctly referenced
            sx={{
              fontSize: `clamp(${fontSizeBase.min}, ${fontSizeBase.preferred}, ${fontSizeBase.max})`,
              fontWeight: '800',
              color: '#FFFFFF',
              alignItems: 'center',
              lineHeight: 'normal',
              marginY: '2',
              marginX: '4',
            }}
            >
              <Text as="span" fontWeight="900" color="#b100ff">Financial Foresight,</Text>&nbsp;
              <Box as="div" className="slidingVertical">
                {animatedWords.map((word, index) => (
                  <Text as="span" key={index} sx={{ opacity: 0 }}>
                  {word}
                  </Text>
                ))}
              </Box>
            </Flex>
            <Text
                  fontFamily="Noto Sans"
                  sx={{
                    fontSize: `clamp(${altFontSizeBase.min}, ${altFontSizeBase.preferred}, ${altFontSizeBase.max})`,
                    color: 'white',
                    mt: '3rem',
                    marginX: '4',
                  }}
                  textAlign="left"
              >
                Alternative Datasets & NLP-Powered <br/> Tradable Insights For Finance
              </Text>
          </Box>
          <Text
            fontFamily="Noto Sans"
            fontSize="1.5xl"
            color="white"
            m="2"
            marginBottom="20px"
            sx={{
              marginX: '4',
            }}
          >
            Copyright © 2024 NLPify Data Technologies Ltd
          </Text>
      </Stack>
    </Flex>
  );
};

export default LoginBranding;
