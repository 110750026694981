import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import Highcharts from 'highcharts';
import StockChart from "./StockChart";
import SentimentChart from "./SentimentChart"; // Your sentiment chart component
import AttentionBuzzChart from "./AttentionBuzzChart";
import { useNavigate } from "react-router-dom";

import CrosshairSynchronizationChart from "./CrosshairSynchronizationChart";

function StockData({
  stock,
  symbol,
  selectedSentiment,
  selectedAttentionBuzz,
  maConfigs,
  setMaConfigs,
  searchTerm,
  handleDeleteSentiment,
  stockDataContainerWidth,
  isCrosshairActive,
  separatorMovedCounter,
  handleDeleteAttentionBuzz,
  resolution,
  firstMaPeriod,
  secondMaPeriod,
  firstMaType,
  secondMaType,
  darkMode,
}) {
  const stockMapping = {
    ALRAJHI: { market: "TADAWUL" },
    SABIC: { market: "TADAWUL" },
    EMAAR: { market: "DFM" },
    DIB: { market: "DFM" },
    ARAMCO: { market: "TADAWUL" },
  };
  
  const [stockData, setStockData] = useState(null);
  const [priceData, setPriceData] = useState(null);
  const [originalStockData,setOriginalStockData] = useState(null);
  const [transformedMAData, setTransformedMAData] = useState({ first: [], second: [] });
  const [movingAverageData, setMovingAverageData] = useState(null);
  const [chartData, setChartData] = useState(null); // Tracks which data to display in the SentimentChart
  const [tooltipData, setTooltipData] = useState({ date: '', value: '' });
  const [crosshairX, setCrosshairX] = useState(null);
  const [hoveredChart, setHoveredChart] = useState(null);
  const [crosshairPosition, setCrosshairPosition] = useState(null);

  const navigate = useNavigate();
  const containerRef = useRef(null); 
  const prevStockDataRef = useRef(); // Ref to store previous stockData for comparison
  const handleMALineRemoved = (maType) => {
    setMaConfigs(prevConfigs => ({
      ...prevConfigs,
      [maType]: { ...prevConfigs[maType], period: null, type: null, data: null }, // Clearing the configuration for the removed MA line
    }));
  };

  const calculateMovingAverage = (data, period, type) => {
    let movingAverages = [];
  
    if (type === 'simple') {
      // Start from the first period and move forward
      for (let i = period - 1; i < data.length; i++) {
        let sum = 0;
        for (let j = 0; j < period; j++) {
          sum += parseFloat(data[i - j].sentiment || 0); // Make sure to access valid indices
        }
        movingAverages.push({
          ...data[i], // Use data[i] to retain the date of the last day in the period
          sentiment: sum / period,
        });
      }
    } else if (type === 'exponential') {
      let multiplier = 2 / (period + 1);
      movingAverages.push({ ...data[0], sentiment: data[0].sentiment }); // Initial EMA value
  
      for (let i = 1; i < data.length; i++) {
        let ema = (data[i].sentiment - movingAverages[movingAverages.length - 1].sentiment) * multiplier + movingAverages[movingAverages.length - 1].sentiment;
        movingAverages.push({
          ...data[i],
          sentiment: ema,
        });
      }
    }
  
    return movingAverages;
  };
  useEffect(() => {

    if (!stock) return;

    const fetchStockData = async () => {
      const token = localStorage.getItem("token");

      try {
        const response = await axios.get(`/api/stocks/${stock.id}/`, {
          headers: { Authorization: `Token ${token}` },
        });

        // Compare fetched stockData with previous to decide on update
        if (JSON.stringify(response.data) !== JSON.stringify(prevStockDataRef.current)) {
          setStockData(response.data);
          setOriginalStockData(response.data)
          prevStockDataRef.current = response.data; // Update ref with new stockData
          const market = stockMapping[stock.symbol]?.market || 'Unknown Market';

        }
      } catch (error) {
        console.error("Error fetching stock data:", error);
        if (error.response?.status === 401) navigate("/login");
      }
    };

    fetchStockData();
  }, [stock, navigate]);
  
  useEffect(() => {
    if (!symbol) return;

    const fetchNews = async () => {
      try {
        const response = await axios.get(`/api/prices/${symbol}`);
        setPriceData(response.data); // Save fetched data in state
  
      } catch (error) {
        console.error('Failed to fetch Pricedata:', error);
      }
    };

    fetchNews();
  }, [symbol]);

  useEffect(() => {
    if (!stockData || !maConfigs) return;

    const transformedFirstMA = maConfigs.first.period 
      ? calculateMovingAverage(stockData, maConfigs.first.period, maConfigs.first.type) 
      : [];
    const transformedSecondMA = maConfigs.second.period 
      ? calculateMovingAverage(stockData, maConfigs.second.period, maConfigs.second.type) 
      : [];

    setTransformedMAData({ 
      first: transformedFirstMA, 
      second: transformedSecondMA 
    });
;
  }, [stockData, maConfigs]);
  // useEffect(() => {
  //   const processDataForResolution = (data, resolution, market) => {
      
  //     if (resolution === 'Weekly') {
  //       // Combine weekly grouping for close and sentiment into one operation
  //       return preprocessForWeeklyGrouping(data, market);
  //     } else if (resolution === 'Monthly') {
  //       // Add logic for monthly grouping (similar to weekly, but by month)
  //       return preprocessForMonthlyGrouping(data, market);
  //     }
  //     return data; // No change for 'Daily'
  //   };
  
  //   if (!stock || !originalStockData) return;
  
  //   const market = stockMapping[stock.symbol]?.market || 'Unknown Market';
  //   const adjustedData = processDataForResolution(originalStockData, resolution, market);
  //   setStockData(adjustedData);
    
  // }, [resolution, stock, originalStockData]);
 
  if (!stock) {
    return <div>Please select a stock</div>;
  }

  if (!stockData && !chartData) {
    return <div style={{
      color: darkMode ? "white" : "black"
      }}>Loading...</div>;
  }


  const showXAxisOnPriceDataOnly = !selectedSentiment && !selectedAttentionBuzz;
  const showXAxisOnSentimentChartOnly = selectedSentiment && !selectedAttentionBuzz;
  const showXAxisOnAttentionBuzzOnly = selectedAttentionBuzz && !selectedSentiment;
  // When both are displayed, show x-axis only on AttentionBuzzChart
  const showXAxisOnBoth = selectedSentiment && selectedAttentionBuzz;

return <CrosshairSynchronizationChart
stockData={stockData}
priceData= {priceData}
selectedSentiment={selectedSentiment}
selectedAttentionBuzz={selectedAttentionBuzz}
maConfigs={maConfigs} // Pass down maConfigs
transformedMAData={transformedMAData} // Pass down transformed MA data
searchTerm={searchTerm}
handleDeleteSentiment={handleDeleteSentiment}
handleDeleteAttentionBuzz={handleDeleteAttentionBuzz}
stockDataContainerWidth={stockDataContainerWidth}
onMALineRemoved={handleMALineRemoved}
isCrosshairActive={isCrosshairActive}
separatorMovedCounter = {separatorMovedCounter}
market={stockMapping[stock.symbol]?.market || 'Unknown Market'}
showXAxisOnAttentionBuzzOnly = {showXAxisOnAttentionBuzzOnly}
showXAxisOnPriceDataOnly = {showXAxisOnPriceDataOnly}
showXAxisOnSentimentChartOnly = {showXAxisOnSentimentChartOnly}
showXAxisOnBoth = {showXAxisOnBoth}
firstMaPeriod = {firstMaPeriod}
secondMaPeriod = {secondMaPeriod}
firstMaType = {firstMaType}
secondMaType = {secondMaType}
resolution = {resolution}
darkMode={darkMode}
/>
}

export default StockData;