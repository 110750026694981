import React, { useEffect, useState, useRef} from 'react';
import Highcharts from 'highcharts/highstock';
import highchartsMore from 'highcharts/highcharts-more';
import PriceIndicator from "highcharts/modules/price-indicator";
import "./CrosshairSynchronizationChart.css";
import closeButton from "../indicator-close.svg";
import closeButtonWhite from "../close-button-white.svg"
import { text } from 'd3';
PriceIndicator(Highcharts);


let isCrossHairDisabled = false;
const CrosshairSynchronizationChart = ({stockData, priceData,stockDataContainerWidth,  selectedSentiment, selectedAttentionBuzz, transformedMAData, searchTerm, handleDeleteSentiment, handleDeleteAttentionBuzz,
   onMALineRemoved, isCrosshairActive, separatorMovedCounter, market, showXAxisOnPriceDataOnly, showXAxisOnSentimentChartOnly, showXAxisOnAttentionBuzzOnly, showXAxisOnBoth, firstMaPeriod, secondMaPeriod, firstMaType, secondMaType, resolution, darkMode}) => {
    const [windowHeight, setWindowHeight] = useState(window.innerHeight);
    const [windowSize, setWindowSize] = useState({
      width: window.innerWidth,
      height: window.innerHeight
  });
  console.log("is dark mode on: " + {darkMode})
  const textColor = darkMode ? '#FFFFFF' : 'black';
  const textColorInvert = darkMode ? 'black' : '#FFFFFF';
  // State to manage chart heights
  const [chartHeights, setChartHeights] = useState({
      chart1: (window.innerHeight - (showXAxisOnPriceDataOnly ? 50 : 0))* 0.925, // Initial values, will be recalculated
      chart2: '0%',
      chart3: '0%',
  });
  const updateChartHeights = () => {
    // Example adjustments, customize based on your needs
    let baseHeight = window.innerHeight - (showXAxisOnPriceDataOnly ? 50 : 0); // Adjust base height if X-axis shown
    let newChartHeights = {
        chart1: baseHeight * 4, // Adjust these ratios based on your content needs
        chart2: selectedSentiment ? baseHeight * 0.2 : '0%',
        chart3: selectedAttentionBuzz ? baseHeight * 0.2 : '0%',
    };

    

    Highcharts.charts.forEach((chart) => {
        if (chart) {
            chart.update({
                chart: {
                    height: newChartHeights[chart.renderTo.id] // Ensure each chart's ID corresponds to keys in newChartHeights
                }
            }, false);
            chart.reflow();
        }
    });
};

  useEffect(() => {
      const handleResize = () => {
          setWindowSize({
              width: window.innerWidth,
              height: window.innerHeight
          });
          updateChartHeights();
      };

      // Add event listener
      window.addEventListener('resize', handleResize);

      // Clean up event listener
      return () => window.removeEventListener('resize', handleResize);
  }, []); // Empty dependency array means this effect runs once on mount

  useEffect(() => {
      // Also call updateChartHeights when selectedSentiment or selectedAttentionBuzz change
      updateChartHeights();
  }, [selectedSentiment, selectedAttentionBuzz, windowSize]);
 
  const chart1Ref = useRef(null);
  

  const lastStockDataRef = useRef(stockData)
  const handleRemoveMALine = (maType) => {
    setMaVisibility((prevVisibility) => ({
      ...prevVisibility,
      [maType]: false, // Update visibility state to hide the MA line
    }));


    // Call the callback prop to notify parent component
    onMALineRemoved(maType);
  };
  const companyExchanges = {
    ALRAJHI: {
      market: "TADAWUL",
    },
    SABIC: {
      market: "TADAWUL",    
    },
    EMAAR: {
      market: "DFM",    
    },
    DIB: {
      market: "DFM",   
    },
    ARAMCO: {
      market: "TADAWUL",
    },
  }
  const [maVisibility, setMaVisibility] = useState({
    first: true,
    second: true
  });
 
  useEffect(() => {

    // Function to trigger reflow on each chart
    const triggerReflow = () => {
      Highcharts.charts.forEach((chart) => {
        if (chart) {
          chart.reflow();
        }
      });
    };
  
    // Trigger reflow upon container width change or separator move
    triggerReflow();
  }, [separatorMovedCounter]);
  useEffect(() => {
    const calculateChartHeight = () => {
      let baseHeight = window.innerHeight - 50;
      let chart1Height, chart2Height, chart3Height;
  
      if (selectedSentiment && !selectedAttentionBuzz) {
        chart1Height = baseHeight * 0.530;
        chart2Height = baseHeight * 0.38800;
        chart3Height = "0%";
      } else if (!selectedSentiment && selectedAttentionBuzz) {
        chart1Height = baseHeight * 0.530;
        chart2Height = "0%";
        chart3Height = baseHeight * 0.38800;
      } else if (selectedSentiment && selectedAttentionBuzz) {
        chart1Height = baseHeight * 0.53425;
        chart2Height = baseHeight * 0.19475;
        chart3Height = baseHeight * 0.19475;
      } else {
        // Only chart1 is displayed
        chart1Height = baseHeight * 0.925;
        chart2Height = "0%";
        chart3Height = "0%";
      }
      return { chart1: chart1Height, chart2: chart2Height, chart3: chart3Height };
    };
  
    // Calculate and set chart heights
    const newChartHeights = calculateChartHeight();
    setChartHeights(newChartHeights);
  
  }, [selectedSentiment, selectedAttentionBuzz, window.innerHeight]);

  useEffect(() => {

 
  }, [isCrosshairActive]);
  let modifying = false;
  
  useEffect(() => {
    const chartContainers = document.querySelectorAll('#container, #container2, #container3');
    chartContainers.forEach(container => {
      // Remove 'crosshair-active' class if isCrossHairDisabled is true
      if (isCrossHairDisabled || !isCrosshairActive) {
        container.classList.remove('crosshair-active');
      } else if (isCrosshairActive || !isCrossHairDisabled) {
        // Only add 'crosshair-active' if isCrosshairActive is true and isCrossHairDisabled is not true
        container.classList.add('crosshair-active');
      } 
    });
  }, [isCrosshairActive]);

  
  useEffect(() => {
    let chart1, chart2, chart3;
    let initialExtremes;
    
    // Preprocess stockData for weekly grouping
    const onDeleteChart = () => {
      handleDeleteSentiment(); // This will update the state in the Home component`
    };

  
    
      const createCharts = (data) => {
          
      // Define the highlightFunction to synchronize crosshairs
      let highlightFunction = function (point) {
        const hoveredTimestamp = point.x; // Timestamp of the hovered point
          
        Highcharts.charts.forEach(chart => {
            if (!chart || !chart.renderTo) return;
    
            chart.series.forEach(series => {
                // Find the point in this series that matches the hovered timestamp
                const matchingPoint = series.points.find(p => p.x === hoveredTimestamp);
                if (matchingPoint) {

                    // Draw the crosshair at the matching point's position
                    if (chart.xAxis[0] && isCrosshairActive) {
                        chart.xAxis[0].drawCrosshair(null, matchingPoint);
                    }

                }
                
            });
        });
        updateLabelsForAllCharts(hoveredTimestamp); // Update labels using the hovered point's timestamp
    };
    
      function updateLabelsForAllCharts(hoveredTimestamp) {
        let currentStockData, currentFirstMAData, currentSecondMAData, currentSentimentData, currentAttBuzzData;
        // Fallback to daily data
        currentStockData = resolution !== "Daily" ? stockData : priceData;
        currentFirstMAData = transformedMAData.first;
        currentSecondMAData = transformedMAData.second;
        currentSentimentData = stockData; // Default sentiment data is the same as stockData
        currentAttBuzzData= stockData;
        // Step 2: Find the corresponding data point for the hovered timestamp
        const dataForDate = currentStockData.find(data => new Date(data.published_date).getTime() === hoveredTimestamp);
        const maFirstData = currentFirstMAData.find(data => new Date(data.published_date).getTime() === hoveredTimestamp);
        const maSecondData = currentSecondMAData.find(data => new Date(data.published_date).getTime() === hoveredTimestamp);
        const sentimentDataForDate = currentSentimentData.find(data => new Date(data.published_date).getTime() === hoveredTimestamp);
        const attBuzzDataForDate = currentAttBuzzData.find(data => new Date(data.published_date).getTime()=== hoveredTimestamp);

        // Update chart2 label with the new sentiment and pctSentiment values
          
        
        if (!sentimentDataForDate) return;

        if (!attBuzzDataForDate) return;
        // Common calculations
        const exchange = companyExchanges[searchTerm]?.market || 'N/A';
        if (dataForDate) {
        const close = dataForDate.close ? dataForDate.close.toFixed(2) : 'N/A';
        const clschange = dataForDate.clschange !== null && dataForDate.clschange !== undefined ? dataForDate.clschange.toFixed(2) : 'N/A';
        const clschangeColor = dataForDate.clschange > 0 ? 'green' : (dataForDate.clschange < 0 ? 'red' : (darkMode ? 'white' : 'black'));
        const pctclschange = dataForDate.pctclschange !== null && dataForDate.pctclschange !== undefined
            ? `${dataForDate.pctclschange > 0 ? '+' : ''}${(dataForDate.pctclschange).toFixed(2)}%` 
            : 'N/A';
        const pctclschangeColor = dataForDate.pctclschange > 0 ? 'green' : (dataForDate.pctclschange < 0 ? 'red' : (darkMode ? 'white' : 'black'));
        if (chart1 && chart1.customLabel) {
          const updatedLabelText = `<span style="font-weight:bold; color: ${textColor};">${searchTerm} | ${exchange} | </span>` +
          `<span style="color: ${textColor};">Close ${close} <span style="color: ${clschangeColor};">${clschange}</span> <span style="color: ${pctclschangeColor};">(${pctclschange})</span></span>`;

            chart1.customLabel.attr({
                text: updatedLabelText
            });
        }

        }
        // Use the sentiment data specific to the selected range for updating sentiment-related labels
        const sentiment = sentimentDataForDate.sentiment !== null && sentimentDataForDate.sentiment !== undefined ? sentimentDataForDate.sentiment.toFixed(2) : 'N/A';
        const sentimentColor = '#8E55FA';
        const pctSentiment = sentimentDataForDate.pctsentiment !== null && sentimentDataForDate.pctsentiment !== undefined
            ? `${sentimentDataForDate.pctsentiment > 0 ? '+' : ''}${sentimentDataForDate.pctsentiment.toFixed(2)}%` 
            : 'N/A';
        const pctSentimentColor = sentimentDataForDate.pctsentiment > 0 ? 'green' : (sentimentDataForDate.pctsentiment < 0 ? 'red' : (darkMode ? 'white' : 'black'));

        const attentionBuzz = attBuzzDataForDate.attention_buzz !== null && attBuzzDataForDate.attention_buzz !== undefined ? attBuzzDataForDate.attention_buzz.toFixed(2) : 'N/A';
        const attentionBuzzColor = "#2CAFFE";
        const pctBuzz = attBuzzDataForDate.pctbuzz !== null && attBuzzDataForDate.pctbuzz !== undefined
            ? `${attBuzzDataForDate.pctbuzz > 0 ? '+' : ''}${attBuzzDataForDate.pctbuzz.toFixed(2)}` 
            : 'N/A';
        const pctBuzzColor = attBuzzDataForDate.pctbuzz > 0 ? 'green' : (attBuzzDataForDate.pctbuzz < 0 ? 'red' : (darkMode ? 'white' : 'black'));
      

        if (chart1 && chart1.maFirstLabel && maFirstData) {
          const maTypeTextFirst = firstMaType === 'simple' ? 'SMA' : firstMaType === 'exponential' ? 'EMA' : 'MA';
          const maFirstText = `<span style="font-weight:bold; color: ${textColor};">Sentiment ${maTypeTextFirst} ${firstMaPeriod}</span> <span style="color: #2CAFFE;">${maFirstData.sentiment.toFixed(2)}</span>`;
            chart1.maFirstLabel.attr({ text: maFirstText });
        }

        if (chart1 && chart1.maSecondLabel && maSecondData) {
          const maTypeTextSecond = secondMaType === 'simple' ? 'SMA' : secondMaType === 'exponential' ? 'EMA' : 'MA';
          const maSecondText = `<span style="font-weight:bold; color: ${textColor};">Sentiment ${maTypeTextSecond} ${secondMaPeriod}</span> <span style="color: orange;">${maSecondData.sentiment.toFixed(2)}</span>`;
            chart1.maSecondLabel.attr({ text: maSecondText });
        }
      
        // Update chart2 label
        if (chart2 && chart2.customSentimentLabel) {

          chart2.customSentimentLabel.attr({
            text:  `<span style="font-weight:bold; color:${textColor};">Sentiment</span> <span style="color: ${sentimentColor};">${sentiment}</span> <span style="color: ${pctSentimentColor};">(${pctSentiment})</span>`
          });
        }
      
        // Update chart3 label
        if (chart3 && chart3.customBuzzLabel) {
          chart3.customBuzzLabel.attr({
            text: `<span style="font-weight:bold; color:${textColor};">Attention Buzz</span> <span style="color: ${attentionBuzzColor};">${attentionBuzz}</span> <span style="color: ${pctBuzzColor};">(${pctBuzz})</span>`
          });
        }
      }
      if (JSON.stringify(lastStockDataRef.current) !== JSON.stringify(stockData)) {
        // stockData has changed, proceed with your chart rendering/update logic here
  
        lastStockDataRef.current = stockData; // Update the ref with the new stockData
      }

      chart1 = Highcharts.stockChart('container', {
        chart: {
          animation: false,  // Disable animation to reduce load
          backgroundColor: darkMode ? '#000000' : '#FFFFFF',
          height: chartHeights.chart1,
          panning: {
            enabled: true,
            type: 'x'
          },
          zooming: {
            mouseWheel: {
                enabled: false,
            }
          },
          style: {
            fontFamily: 'Lato' // Apply Lato font
          },
          marginRight:42,
          marginLeft: (transformedMAData && (transformedMAData.first.length > 0 || transformedMAData.second.length > 0)) ? 57: 10,
          events: {
            
            selection(event) {
              return false;
            },
            load: function () {
              let closeButtonIcon = darkMode ? closeButtonWhite : closeButton;
              const exchange = companyExchanges[searchTerm]?.market || 'N/A';
              const lastDataPoint = (resolution !== "Daily" ? stockData : priceData)[(resolution !== "Daily" ? stockData : priceData).length - 1] || {};
              const close = lastDataPoint.close ? lastDataPoint.close.toFixed(2) : 'N/A';
              const clschange = lastDataPoint.clschange !== null && lastDataPoint.clschange !== undefined ? lastDataPoint.clschange.toFixed(2) : 'N/A';
              const clschangeColor = lastDataPoint.clschange > 0 ? 'green' : (lastDataPoint.clschange < 0 ? 'red' : (darkMode ? 'white' : 'black'));
              const pctclschange = lastDataPoint.pctclschange !== null && lastDataPoint.pctclschange !== undefined
                  ? `${lastDataPoint.pctclschange > 0 ? '+' : ''}${(lastDataPoint.pctclschange).toFixed(2)}%` 
                  : 'N/A';
              const pctclschangeColor = lastDataPoint.pctclschange > 0 ? 'green' : (lastDataPoint.pctclschange < 0 ? 'red' : (darkMode ? 'white' : 'black'));
              
              // Construct the label with HTML styling for the bold part
              
              const labelText = `<span style="font-weight:bold; color: ${textColor};">${searchTerm} | ${exchange} | </span>` +
                `<span style="color: ${textColor};">Close ${close} <span style="color: ${clschangeColor};">${clschange}</span> <span style="color: ${pctclschangeColor};">(${pctclschange})</span></span>`;

              // Render the label using useHTML: true to enable HTML rendering
              this.customLabel = this.renderer.text(labelText, 10, 20)
              .css({ color: '#333', fontSize: '14px' })
              .add()
              .attr({
              useHTML: true, // Enable HTML rendering
              zIndex: 5 // Ensure the label is on top; adjust zIndex as needed
              });

              if (transformedMAData && transformedMAData.first.length > 0 && maVisibility.first) {
                const initialMAFirst = transformedMAData.first[transformedMAData.first.length - 1];
                const maTypeTextFirst = firstMaType === 'simple' ? 'SMA' : firstMaType === 'exponential' ? 'EMA' : 'MA';
                const maFirstText = `<span style="font-weight:bold; color: ${textColor};">Sentiment ${maTypeTextFirst} ${firstMaPeriod}</span> <span style="color: #2CAFFE;">${initialMAFirst.sentiment.toFixed(2)}</span>`;

                this.maFirstLabel = this.renderer.text(maFirstText, 10, 40)
                    .css({ color: '#007bff', fontSize: '14px' })
                    .add()
                    .attr({zIndex: 3
                    })
                this.maFirstLabel.attr({
                    useHTML: true
                });
                let maFirstLabelBBox = this.maFirstLabel.getBBox();
                let closeButtonBgFirst = this.renderer.rect(maFirstLabelBBox.x + maFirstLabelBBox.width + 12 - 5, maFirstLabelBBox.y, 20, 20)
                .attr({
                  fill: 'rgba(255, 255, 255, 0)', // Initially transparent
                  zIndex: -1, // Ensure it is behind the SVG button
                  cursor: 'pointer'
                })
                .add();
                let svgCloseButtonXFirst = maFirstLabelBBox.x + maFirstLabelBBox.width + 18 - 5 ;
                let svgCloseButtonYFirst = maFirstLabelBBox.y + (maFirstLabelBBox.height / 2) - 7 - 4;
                // Close Button for MA First Label
                let closeSVGButtonFirst = this.renderer.image(closeButtonIcon, svgCloseButtonXFirst - 7, svgCloseButtonYFirst , 25, 25)
                .css({ cursor: 'pointer' })
                .attr({ zIndex: 3, visibility: 'hidden' })
                .add()
                .on('mouseover', () => {
                  isCrossHairDisabled = true;
                  closeButtonBgFirst.attr({ fill: darkMode ? "#454c5e" : "#f0f0f0" }); // Change to grey
                  borderFirst.attr({ visibility: 'visible' });
                  closeSVGButtonFirst.attr({ visibility: 'visible' });
                })
                .on('mouseout', () => {
                  isCrossHairDisabled = false;
                  borderFirst.attr({ visibility: 'hidden' });
                  closeSVGButtonFirst.attr({ visibility: 'hidden' });
                  closeButtonBgFirst.attr({ fill: 'rgba(255, 255, 255, 0)' }); // Change back to transparent
                });
                    
        
                // Invisible Hit Area around Close Button for MA First Label
                let hitAreaFirstX = closeSVGButtonFirst.attr('x');
                let hitAreaFirstY = closeSVGButtonFirst.attr('y') ;
                let hitAreaFirstWidth = 20; // Adjust size for easier clickability
                let hitAreaFirstHeight = 18.5; // Adjust size for easier clickability
        
                let maFirstButtonArea = this.renderer.rect(hitAreaFirstX, hitAreaFirstY + 2, hitAreaFirstWidth, hitAreaFirstHeight)
                    .attr({ fill: 'rgba(255, 255, 255, 0)', cursor: 'pointer' })
                    .add()
                    .on('click', (e) => {
                        e.stopPropagation();
                        const series = this.series.find(s => s.name === 'MA First');
                        if (series) series.remove(true);
                        onMALineRemoved('first');
                        this.maFirstLabel.destroy();
                        closeButtonBgFirst.destroy();
                        closeSVGButtonFirst.destroy();
                    })
                    .on('mouseover', () => {;
                        isCrossHairDisabled = true
                        
                        borderFirst.attr({ visibility: 'visible' });
                        closeSVGButtonFirst.attr({ visibility: 'visible' });
                        closeButtonBgFirst.attr({ fill: darkMode ? "#454c5e" : "#f0f0f0" }); // Change background to grey
                    })
                    .on('mouseout', () => {
                        isCrossHairDisabled = false;
                        borderFirst.attr({ visibility: 'hidden' });
                        closeSVGButtonFirst.attr({ visibility: 'hidden' });
                        closeButtonBgFirst.attr({ fill: 'rgba(255, 255, 255, 0)' });
                    });
        
                // Adjusting Border and Visibility for MA First Label
                let borderFirst = this.renderer.rect(maFirstLabelBBox.x - 5, maFirstLabelBBox.y - 1, maFirstLabelBBox.width + 33, maFirstLabelBBox.height + 4, 2)
                    .attr({ stroke: darkMode ? "white" : "black", 'stroke-width': 1, fill: 'none', visibility: 'hidden', zIndex: 3 })
                    .add();
                closeButtonBgFirst.on('mouseover', () => {
                  isCrossHairDisabled = true
                  borderFirst.attr({ visibility: 'visible' });
                  closeButtonBgFirst.attr({ fill: '#f0f0f0' }); // Change to grey
                  closeSVGButtonFirst.attr({ visibility: 'visible' });
                }).on('mouseout', () => {
                  isCrossHairDisabled = false;
                  borderFirst.attr({ visibility: 'hidden' });
                  closeButtonBgFirst.attr({ fill: 'rgba(255, 255, 255, 0)' }); // Change back to transparent
                  closeSVGButtonFirst.attr({ visibility: 'hidden' });
                });
                this.renderer.rect(maFirstLabelBBox.x - 5, maFirstLabelBBox.y, maFirstLabelBBox.width + 35, maFirstLabelBBox.height )
                    .attr({ fill: 'rgba(255, 255, 255, 0)', cursor: 'default', zIndex: 3 })
                    .add()
                    .on('mouseover', () => {
                        isCrossHairDisabled = true
                        
                        borderFirst.attr({ visibility: 'visible' });
                        closeSVGButtonFirst.attr({ visibility: 'visible' });
                    })
                    .on('mouseout', () => {
                        isCrossHairDisabled = false
                        borderFirst.attr({ visibility: 'hidden' });
                        closeSVGButtonFirst  .attr({ visibility: 'hidden' });
                    });
                    maFirstButtonArea.toFront();
              }
              if (transformedMAData && transformedMAData.second.length > 0 && maVisibility.second) {
                const initialMASecond = transformedMAData.second[transformedMAData.second.length - 1];
                const maTypeTextSecond = secondMaType === 'simple' ? 'SMA' : secondMaType === 'exponential' ? 'EMA' : 'MA';
                const maSecondText = `<span style="font-weight:bold; color: ${textColor};">Sentiment ${maTypeTextSecond} ${secondMaPeriod}</span> <span style="color: orange;">${initialMASecond.sentiment.toFixed(2)}</span>`;
                this.maSecondLabel = this.renderer.text(maSecondText, 10, 60) // Adjusted Y position to be below MA First
                    .css({ color: '#007bff', fontSize: '14px' })
                    .add()
                    .attr({
                    })
                this.maSecondLabel.attr({
                    useHTML: true
                });
                let maSecondLabelBBox = this.maSecondLabel.getBBox();
                let closeButtonBgSecond = this.renderer.rect(maSecondLabelBBox.x + maSecondLabelBBox.width + 12, maSecondLabelBBox.y, 20, 20)
                .attr({
                  fill: 'rgba(255, 255, 255, 0)', // Initially transparent
                  zIndex: -1, // Ensure it is behind the SVG button
                  cursor: 'pointer'
                })
                .add();
                let svgCloseButtonXSecond = maSecondLabelBBox.x + maSecondLabelBBox.width + 18;
                let svgCloseButtonYSecond = maSecondLabelBBox.y + (maSecondLabelBBox.height / 2) - 7 - 4;
                // Close Button for MA Second Label
                let closeSVGButtonSecond = this.renderer.image(closeButtonIcon, svgCloseButtonXSecond - 7, svgCloseButtonYSecond, 25, 25)
                  .css({ cursor: 'pointer' })
                  .attr({ zIndex: 3, visibility: 'hidden' })
                  .add()
                  .on('mouseover', () => {
                    isCrossHairDisabled = true
                    closeButtonBgSecond.attr({ fill: darkMode ? "#454c5e" : "#f0f0f0" }); // Change to grey
                    borderSecond.attr({ visibility: 'visible' });
                    closeSVGButtonSecond.attr({ visibility: 'visible' });
                  })
                  .on('mouseout', () => {
                    isCrossHairDisabled = false;
                    borderSecond.attr({ visibility: 'hidden' });
                    closeSVGButtonSecond.attr({ visibility: 'hidden' });
                    closeButtonBgSecond.attr({ fill: 'rgba(255, 255, 255, 0)' }); // Change back to transparent
                  });
            
                // Invisible Hit Area around Close Button for MA Second Label
                let hitAreaSecondX =  closeSVGButtonSecond.attr('x');
                let hitAreaSecondY =  closeSVGButtonSecond.attr('y');
                let hitAreaSecondWidth = 20; // Adjust size for easier clickability
                let hitAreaSecondHeight = 18.5; // Adjust size for easier clickability
            
                let maSecondButtonArea = this.renderer.rect(hitAreaSecondX, hitAreaSecondY + 1.5, hitAreaSecondWidth, hitAreaSecondHeight)
                    .attr({ fill: 'rgba(255, 255, 255, 0)', cursor: 'pointer' })
                    .add()
                    .on('click', (e) => {
                        e.stopPropagation();
                        const series = this.series.find(s => s.name === 'MA Second');
                        if (series) series.remove(true);
                        onMALineRemoved('second');
                        this.maSecondLabel.destroy();
                        closeButtonBgSecond.destroy();
                        closeSVGButtonSecond.destroy();
                    })
                    .on('mouseover', () => {
                        isCrossHairDisabled = true
                        closeSVGButtonSecond.attr({ visibility: 'visible' });
                        closeButtonBgSecond.attr({ fill: darkMode ? "#454c5e" : "#f0f0f0" });
                        borderSecond.attr({ visibility: 'visible' });
                    })
                    .on('mouseout', () => {
                        isCrossHairDisabled = false;
                        closeSVGButtonSecond.attr({ visibility: 'hidden' });
                        closeButtonBgSecond.attr({ fill: 'rgba(255, 255, 255, 0)' });
                        borderSecond.attr({ visibility: 'hidden' });
                    });
            
                // Adjusting Border and Visibility for MA Second Label
                let borderSecond = this.renderer.rect(maSecondLabelBBox.x - 5, maSecondLabelBBox.y - 1, maSecondLabelBBox.width + 37, maSecondLabelBBox.height + 4, 2)
                    .attr({ stroke: darkMode ? "white" : "black", 'stroke-width': 1, fill: 'none', visibility: 'hidden' })
                    .add();
                closeButtonBgSecond.on('mouseover', () => {
                    isCrossHairDisabled = true
                    borderSecond.attr({ visibility: 'visible' });
                    closeButtonBgSecond.attr({ fill: '#f0f0f0' }); // Change to grey
                    closeSVGButtonSecond.attr({ visibility: 'visible' });
                  }).on('mouseout', () => {
                    isCrossHairDisabled = false;
                    borderSecond.attr({ visibility: 'hidden' });
                    closeButtonBgSecond.attr({ fill: 'rgba(255, 255, 255, 0)' }); // Change back to transparent
                    closeSVGButtonSecond.attr({ visibility: 'hidden' });
                  });
                this.renderer.rect(maSecondLabelBBox.x - 5, maSecondLabelBBox.y, maSecondLabelBBox.width + 35, maSecondLabelBBox.height)
                    .attr({ fill: 'rgba(255, 255, 255, 0)', cursor: 'default',})
                    .add()
                    .on('mouseover', () => {
                        isCrossHairDisabled = true

                        borderSecond.attr({ visibility: 'visible'});
                        closeSVGButtonSecond.attr({ visibility: 'visible' });
                    })
                    .on('mouseout', () => {
                        isCrossHairDisabled = false;

                        borderSecond.attr({ visibility: 'hidden' });
                        closeSVGButtonSecond.attr({ visibility: 'hidden' });
                    });
            
                maSecondButtonArea.toFront();
            }
            
          },
          },
        },
        crosshair: false,
        title: {
          text: ''
        },
        tooltip: {
          shared: true,
          formatter: function() {
            return false;
          }
        },
        xAxis: {
          type: 'datetime',
          visible: showXAxisOnPriceDataOnly,
          crosshair: false,
          lineColor: darkMode ? "#FFFFFF" : "#000000",
          tickColor: darkMode ? "#FFFFFF" : "#000000",
          labels: {
            style:{
              color: darkMode ? '#FFFFFF' : '#000000',
            }
          },
          events: {
            setExtremes: function(e) {
              if (!modifying) {
                modifying = true;
                chart2.xAxis[0].setExtremes(e.min, e.max, true, false);
                chart3.xAxis[0].setExtremes(e.min, e.max, true, false);
                modifying = false;
              }
            } 
          },

          
        },
        yAxis: [{
          crosshair: false,
          title: {
            enabled: false,
          },
          gridLineColor: darkMode ? "#2a2e39" : "#E6E6E6",
          left: (transformedMAData.first.length || transformedMAData.second.length) ? 96 : 52,
          labels: {
            x: -40,
            style:{
              color: darkMode ? '#FFFFFF' : '#000000',
            }
          },
          opposite: true, // Original Y-axis on the right
          offset: 25,
          showLastLabel: true,
        }, {
          // New Y axis for the MA data
          title: { enabled: false },
          gridLineColor: darkMode ? "#2a2e39" : "#E6E6E6",
          labels: {
            style:{
              color: darkMode ? '#FFFFFF' : '#000000',
            }
          },
          opposite: false, // Place this Y axis on the opposite side
          visible: transformedMAData.first.length > 0 || transformedMAData.second.length > 0
        }],
      
        credits: {
          enabled: false,
        },
        exporting: {
          enabled: false,
        },
        navigator: {
          enabled: false,
        },
        scrollbar: {
          enabled: false,
        },
        crosshair: {
          enabled: false,
        },
        rangeSelector: {
          enabled: true,
          inputEnabled: false,
          selected: 4,
        },
        sync: {
          extremes: true
        },
        plotOptions: {
          series: {
              // Other series options...

              states: {
                  inactive: {
                      opacity: 1
                  }
              },
              lastVisiblePrice: {
                label: {
                formatter: function () {
                  // This function allows you to return a custom formatted string
                  // For example, rounding the last visible price to two decimal places
                  return this.y.toFixed(2);
                  
                },
                verticalAlign: 'middle' // This should vertically center the text
              }
            }
          },
      },
        series: [{
          data: (resolution !== "Daily" ? stockData : priceData).map(data => [new Date(data.published_date).getTime(), data.close]),
          enabledCrosshairs: true,
          visible: true,
          lastVisiblePrice: {
            enabled: true,
            label: {
              enabled: true,
              padding: 3,
              format: '{value:.2f}',
              backgroundColor: textColor,
              shape: 'rect',
               style: {
                color:textColorInvert,
                backgroundColor: textColor,
                fontWeight: 'normal',
                fontSize: '	0.8em', // Set your desired font size here
              },
            }
          },
          type: 'line',
          name: 'Close',
          color: darkMode ? '#FFFFFF' : '#000000',
          lineWidth: 1.5,
          point: {
            events: {
              mouseOver(e) {
                let point = this;
                highlightFunction(point);
              },
              mouseOut(e) {
                const exchange = companyExchanges[searchTerm]?.market || 'N/A';
                const lastDataPoint = (resolution !== "Daily" ? stockData : priceData)[(resolution !== "Daily" ? stockData : priceData).length - 1] || {};
                const close = lastDataPoint.close ? lastDataPoint.close.toFixed(2) : 'N/A';
                const clschange = lastDataPoint.clschange !== null && lastDataPoint.clschange !== undefined ? lastDataPoint.clschange.toFixed(2) : 'N/A';
                const clschangeColor = lastDataPoint.clschange > 0 ? 'green' : (lastDataPoint.clschange < 0 ? 'red' : (darkMode ? 'white' : 'black'));
                const pctclschange = lastDataPoint.pctclschange !== null && lastDataPoint.pctclschange !== undefined
                  ? `${lastDataPoint.pctclschange > 0 ? '+' : ''}${(lastDataPoint.pctclschange).toFixed(2)}%` 
                  : 'N/A';
                  const pctclschangeColor = lastDataPoint.pctclschange > 0 ? 'green' : (lastDataPoint.pctclschange < 0 ? 'red' : (darkMode ? 'white' : 'black'));
                const textColor = darkMode ? 'white' : 'black';
              // Construct the label with HTML styling for the bold part  

                const labelText = `<span style="font-weight:bold; color: ${textColor};">${searchTerm} | ${exchange} | </span>` +
                `<span style="color: ${textColor};">Close ${close} <span style="color: ${clschangeColor};">${clschange}</span> <span style="color: ${pctclschangeColor};">(${pctclschange})</span></span>`;
                chart1.customLabel.attr({
                  text: labelText
                });
                if (transformedMAData && (transformedMAData.first.length > 0)) {
                  const initialMAFirst = transformedMAData.first[transformedMAData.first.length - 1];
                  const maTypeTextFirst = firstMaType === 'simple' ? 'SMA' : firstMaType === 'exponential' ? 'EMA' : 'MA';
                  const maFirstText = `<span style="font-weight:bold; color: ${textColor};">Sentiment ${maTypeTextFirst} ${firstMaPeriod}</span> <span style="color: #2CAFFE;">${initialMAFirst.sentiment.toFixed(2)}</span>`;
                  chart1.maFirstLabel.attr({ text: maFirstText });
                }
                if (transformedMAData && (transformedMAData.second.length > 0)) {
                  const initialMASecond = transformedMAData.second[transformedMAData.second.length - 1];
                  const maTypeTextSecond = secondMaType === 'simple' ? 'SMA' : secondMaType === 'exponential' ? 'EMA' : 'MA';
                  const maSecondText = `<span style="font-weight:bold; color: ${textColor};">Sentiment ${maTypeTextSecond} ${secondMaPeriod}</span> <span style="color: orange;">${initialMASecond.sentiment.toFixed(2)}</span>`;
                  chart1.maSecondLabel.attr({ text: maSecondText });
                }
                if (selectedSentiment) {
                  const lastDataPoint = stockData[stockData.length - 1] || {};
                  const sentiment = lastDataPoint.sentiment !== null && lastDataPoint.sentiment !== undefined ? lastDataPoint.sentiment.toFixed(2) : 'N/A';
                  const sentimentColor = '#8E55FA';
                  const pctSentiment = lastDataPoint.pctsentiment !== null && lastDataPoint.pctsentiment !== undefined
                      ? `${lastDataPoint.pctsentiment > 0 ? '+' : ''}${lastDataPoint.pctsentiment.toFixed(2)}%` 
                      : 'N/A';
                  const pctSentimentColor = lastDataPoint.pctsentiment > 0 ? 'green' : (lastDataPoint.pctsentiment < 0 ? 'red' : (darkMode ? 'white' : 'black'));
                  const sentimentText = `<span style="font-weight:bold; color: ${textColor}">Sentiment</span> <span style="color: ${sentimentColor};">${sentiment}</span> <span style="color: ${pctSentimentColor};">(${pctSentiment})</span>`;
                  chart2.customSentimentLabel.attr({
                    text:  sentimentText
                  });
                }
                if (selectedAttentionBuzz) {
                  const lastDataPoint = stockData[stockData.length - 1] || {};
                  const attentionBuzz = lastDataPoint.attention_buzz !== null && lastDataPoint.attention_buzz !== undefined ? lastDataPoint.attention_buzz.toFixed(2) : 'N/A';
                  const attentionBuzzColor = "#2CAFFE";
                  const pctBuzz = lastDataPoint.pctbuzz !== null && lastDataPoint.pctbuzz !== undefined
                    ? `${lastDataPoint.pctbuzz > 0 ? '+' : ''}${lastDataPoint.pctbuzz.toFixed(2)}` 
                    : 'N/A';
                  const pctBuzzColor = lastDataPoint.pctbuzz > 0 ? 'green' : (lastDataPoint.pctbuzz < 0 ? 'red' : (darkMode ? 'white' : 'black'));
                  const buzzText = `<span style="font-weight:bold; color: ${textColor}">Attention Buzz</span> <span style="color: ${attentionBuzzColor};">${attentionBuzz}</span> <span style="color: ${pctBuzzColor};">(${pctBuzz})</span>`;
                  chart3.customBuzzLabel.attr({
                    text: buzzText
                  });
                }
              }
            }
          },
        }, {
          // MA First series configuration
          data: transformedMAData.first.map(data => [new Date(data.published_date).getTime(), data.sentiment]),
          lastVisiblePrice: {
            enabled: true,
            label: {
              enabled: true,
              padding: 3,
              format: '{value:.2f}',
              shape: 'rect',
              backgroundColor: "#2CAFFE ",
              style: {
                color: 'white',
                fontWeight: 'normal',
                fontSize: '	0.8em', // Set your desired font size here
                textAlign: 'center'
              }
            }
          },
          type: 'line',
          name: 'MA First',
          color: '#2CAFFE', // Example color for the first MA series
          lineWidth: 1.5,
          point: {
            events: {
              mouseOver(e) {
                let point = this;
                highlightFunction(point);
              },
              mouseOut(e) {
                
                const exchange = companyExchanges[searchTerm]?.market || 'N/A';
                const lastDataPoint = (resolution !== "Daily" ? stockData : priceData)[(resolution !== "Daily" ? stockData : priceData).length - 1] || {};
                const close = lastDataPoint.close ? lastDataPoint.close.toFixed(2) : 'N/A';
                const clschange = lastDataPoint.clschange !== null && lastDataPoint.clschange !== undefined ? lastDataPoint.clschange.toFixed(2) : 'N/A';
                const clschangeColor = lastDataPoint.clschange > 0 ? 'green' : (lastDataPoint.clschange < 0 ? 'red' : (darkMode ? 'white' : 'black'));
                const pctclschange = lastDataPoint.pctclschange !== null && lastDataPoint.pctclschange !== undefined
                  ? `${lastDataPoint.pctclschange > 0 ? '+' : ''}${(lastDataPoint.pctclschange).toFixed(2)}%` 
                  : 'N/A';
                  const pctclschangeColor = lastDataPoint.pctclschange > 0 ? 'green' : (lastDataPoint.pctclschange < 0 ? 'red' : (darkMode ? 'white' : 'black'));
   
                // Construct the label with HTML styling for the bold part  
                const labelText = `<span style="font-weight:bold; color: ${textColor}">${searchTerm} | ${exchange} | </span>` +
                `<span style="color: ${textColor};">Close ${close} <span style="color: ${clschangeColor};">${clschange}</span> <span style="color: ${pctclschangeColor};">(${pctclschange})</span></span>`;
                chart1.customLabel.attr({
                  text: labelText
                });
                const initialMAFirst = transformedMAData.first[transformedMAData.first.length - 1];
                const maTypeTextFirst = firstMaType === 'simple' ? 'SMA' : firstMaType === 'exponential' ? 'EMA' : 'MA';
                const maFirstText = `<span style="font-weight:bold; color: ${textColor};">Sentiment ${maTypeTextFirst} ${firstMaPeriod}</span> <span style="color: #2CAFFE;">${initialMAFirst.sentiment.toFixed(2)}</span>`;
                chart1.maFirstLabel.attr({ text: maFirstText });
                if (transformedMAData && (transformedMAData.second.length > 0)) {
                  const initialMASecond = transformedMAData.second[transformedMAData.second.length - 1];
                  const maTypeTextSecond = secondMaType === 'simple' ? 'SMA' : secondMaType === 'exponential' ? 'EMA' : 'MA';
                  const maSecondText = `<span style="font-weight:bold; color: ${textColor};">Sentiment ${maTypeTextSecond} ${secondMaPeriod}</span> <span style="color: orange;">${initialMASecond.sentiment.toFixed(2)}</span>`;
                  chart1.maSecondLabel.attr({ text: maSecondText });
                }
                if (selectedAttentionBuzz) {
                  const lastDataPoint = stockData[stockData.length - 1] || {};
                  const attentionBuzz = lastDataPoint.attention_buzz !== null && lastDataPoint.attention_buzz !== undefined ? lastDataPoint.attention_buzz.toFixed(2) : 'N/A';
                  const attentionBuzzColor = "#2CAFFE";
                  const pctBuzz = lastDataPoint.pctbuzz !== null && lastDataPoint.pctbuzz !== undefined
                    ? `${lastDataPoint.pctbuzz > 0 ? '+' : ''}${lastDataPoint.pctbuzz.toFixed(2)}` 
                    : 'N/A';
                  const pctBuzzColor = lastDataPoint.pctbuzz > 0 ? 'green' : (lastDataPoint.pctbuzz < 0 ? 'red' : (darkMode ? 'white' : 'black'));
                  const buzzText = `<span style="font-weight:bold; color: ${textColor};">Attention Buzz</span> <span style="color: ${attentionBuzzColor};">${attentionBuzz}</span> <span style="color: ${pctBuzzColor};">(${pctBuzz})</span>`;
                  chart3.customBuzzLabel.attr({
                    text: buzzText
                  });
                }
                if (selectedSentiment) {
                  const lastDataPoint = stockData[stockData.length - 1] || {};
                  const sentiment = lastDataPoint.sentiment !== null && lastDataPoint.sentiment !== undefined ? lastDataPoint.sentiment.toFixed(2) : 'N/A';
                  const sentimentColor = '#8E55FA';
                  const pctSentiment = lastDataPoint.pctsentiment !== null && lastDataPoint.pctsentiment !== undefined
                      ? `${lastDataPoint.pctsentiment > 0 ? '+' : ''}${lastDataPoint.pctsentiment.toFixed(2)}%` 
                      : 'N/A';
                  const pctSentimentColor = lastDataPoint.pctsentiment > 0 ? 'green' : (lastDataPoint.pctsentiment < 0 ? 'red' : (darkMode ? 'white' : 'black'));
                  const sentimentText = `<span style="font-weight:bold; color: ${textColor};">Sentiment</span> <span style="color: ${sentimentColor};">${sentiment}</span> <span style="color: ${pctSentimentColor};">(${pctSentiment})</span>`;
                  chart2.customSentimentLabel.attr({
                    text:  sentimentText
                  });
                }
              }
            }
          },
          yAxis: 1, // Associate with the new Y axis

      }, {
          // MA Second series configuration
          data: transformedMAData.second.map(data => [new Date(data.published_date).getTime(), data.sentiment]),
          lastVisiblePrice: {
            enabled: true,
            label: {
              enabled: true,
              padding: 3,
              format: '{value:.2f}',
              backgroundColor: "orange",
              shape: 'rect',
              style: {
                color: 'white',
                fontWeight: 'normal',
                fontSize: '	0.8em', // Set your desired font size here
                textAlign: 'center'
              }
            }
          },
          type: 'line',
          name: 'MA Second',
          color: 'orange', // Example color for the second MA series
          lineWidth: 1.5,

          point: {
            events: {
              mouseOver(e) {
                let point = this;
                highlightFunction(point);
              },
              mouseOut(e) {
                const exchange = companyExchanges[searchTerm]?.market || 'N/A';
                const lastDataPoint = (resolution !== "Daily" ? stockData : priceData)[(resolution !== "Daily" ? stockData : priceData).length - 1] || {};
                const close = lastDataPoint.close ? lastDataPoint.close.toFixed(2) : 'N/A';
                const clschange = lastDataPoint.clschange !== null && lastDataPoint.clschange !== undefined ? lastDataPoint.clschange.toFixed(2) : 'N/A';
                const clschangeColor = lastDataPoint.clschange > 0 ? 'green' : (lastDataPoint.clschange < 0 ? 'red' : (darkMode ? 'white' : 'black'));
                const pctclschange = lastDataPoint.pctclschange !== null && lastDataPoint.pctclschange !== undefined
                  ? `${lastDataPoint.pctclschange > 0 ? '+' : ''}${(lastDataPoint.pctclschange).toFixed(2)}%` 
                  : 'N/A';
                  const pctclschangeColor = lastDataPoint.pctclschange > 0 ? 'green' : (lastDataPoint.pctclschange < 0 ? 'red' : (darkMode ? 'white' : 'black'));
   
              // Construct the label with HTML styling for the bold part  
                const labelText = `<span style="font-weight:bold; color: ${textColor};">${searchTerm} | ${exchange} | </span>` +
                `<span style="color: ${textColor};">Close ${close} <span style="color: ${clschangeColor};">${clschange}</span> <span style="color: ${pctclschangeColor};">(${pctclschange})</span></span>`;
                chart1.customLabel.attr({
                  text: labelText
                });
                const initialMASecond = transformedMAData.second[transformedMAData.second.length - 1];
                const maTypeTextSecond = secondMaType === 'simple' ? 'SMA' : secondMaType === 'exponential' ? 'EMA' : 'MA';
                const maSecondText = `<span style="font-weight:bold; color: ${textColor};">Sentiment ${maTypeTextSecond} ${secondMaPeriod}</span> <span style="color: orange;">${initialMASecond.sentiment.toFixed(2)}</span>`;
                chart1.maSecondLabel.attr({ text: maSecondText });
                if (transformedMAData && (transformedMAData.first.length > 0)) {
                  const initialMAFirst = transformedMAData.first[transformedMAData.first.length - 1];
                  const maTypeTextFirst = firstMaType === 'simple' ? 'SMA' : firstMaType === 'exponential' ? 'EMA' : 'MA';
                  const maFirstText = `<span style="font-weight:bold; color: ${textColor};">Sentiment ${maTypeTextFirst} ${firstMaPeriod}</span> <span style="color: #2CAFFE;">${initialMAFirst.sentiment.toFixed(2)}</span>`;
                  chart1.maFirstLabel.attr({ text: maFirstText });
                }
                if (selectedSentiment) {
                  const lastDataPoint = stockData[stockData.length - 1] || {};
                  const sentiment = lastDataPoint.sentiment !== null && lastDataPoint.sentiment !== undefined ? lastDataPoint.sentiment.toFixed(2) : 'N/A';
                  const sentimentColor = '#8E55FA';
                  const pctSentiment = lastDataPoint.pctsentiment !== null && lastDataPoint.pctsentiment !== undefined
                      ? `${lastDataPoint.pctsentiment > 0 ? '+' : ''}${lastDataPoint.pctsentiment.toFixed(2)}%` 
                      : 'N/A';
                  const pctSentimentColor = lastDataPoint.pctsentiment > 0 ? 'green' : (lastDataPoint.pctsentiment < 0 ? 'red' : (darkMode ? 'white' : 'black'));
                  const sentimentText = `<span style="font-weight:bold; color: ${textColor};">Sentiment</span> <span style="color: ${sentimentColor};">${sentiment}</span> <span style="color: ${pctSentimentColor};">(${pctSentiment})</span>`;
                  chart2.customSentimentLabel.attr({
                    text:  sentimentText
                  });
                }
                if (selectedAttentionBuzz) {
                  const lastDataPoint = stockData[stockData.length - 1] || {};
                  const attentionBuzz = lastDataPoint.attention_buzz !== null && lastDataPoint.attention_buzz !== undefined ? lastDataPoint.attention_buzz.toFixed(2) : 'N/A';
                  const attentionBuzzColor = "#2CAFFE";
                  const pctBuzz = lastDataPoint.pctbuzz !== null && lastDataPoint.pctbuzz !== undefined
                    ? `${lastDataPoint.pctbuzz > 0 ? '+' : ''}${lastDataPoint.pctbuzz.toFixed(2)}` 
                    : 'N/A';
                  const pctBuzzColor = lastDataPoint.pctbuzz > 0 ? 'green' : (lastDataPoint.pctbuzz < 0 ? 'red' : (darkMode ? 'white' : 'black'));
                  const buzzText = `<span style="font-weight:bold; color: ${textColor};">Attention Buzz</span> <span style="color: ${attentionBuzzColor};">${attentionBuzz}</span> <span style="color: ${pctBuzzColor};">(${pctBuzz})</span>`;
                  chart3.customBuzzLabel.attr({
                    text: buzzText
                  });
                }
              }
            }
          },
          yAxis: 1, // Also associate with the new Y axis
      }
    ],
    });
      
    
  
      // Create chart2 with your data
      chart2 =  Highcharts.stockChart('container2', {
        // Configuration for chart2
        xAxis: {
          crosshair: false,
          type: 'datetime',
          visible: showXAxisOnSentimentChartOnly,
          lineColor: darkMode ? "#FFFFFF" : "#000000",
          tickColor: darkMode ? "#FFFFFF" : "#000000",
          labels: {
            style:{
              color: darkMode ? '#FFFFFF' : '#000000',
            }
          },
          events: {
            setExtremes: function(e) {
              if (!modifying) {
                modifying = true;
                chart1.xAxis[0].setExtremes(e.min, e.max, true, false);
                chart3.xAxis[0].setExtremes(e.min, e.max, true, false);
                modifying = false;
              }
            }
          },
        },

        yAxis: {
          crosshair: false,
          opposite: true,
          title:{
            enabled: false,
          },
          gridLineColor: darkMode ? "#2a2e39" : "#E6E6E6",
          left: (transformedMAData.first.length || transformedMAData.second.length) ? 90 : 50,
          labels: {
            x: -38,
            style:{
              color: darkMode ? '#FFFFFF' : '#000000',
            }
          },
          opposite: true, // Original Y-axis on the right
          offset: 25,
          floor: -1,
          ceiling: 1,
          showLastLabel: true,
          allowDecimals: true,
        },
        chart: {
          animation: false,  // Disable animation to reduce load
          backgroundColor: darkMode ? '#000000' : '#FFFFFF',
          height: chartHeights.chart2,
          panning: {
            enabled: true,
            type: 'x'
          },  
          zooming: {
            mouseWheel: {
                enabled: false,
            }
          },
          type: 'spline',
          style: {
            fontFamily: 'Lato' // Apply Lato font
          },
          marginRight:42,
          marginLeft: (transformedMAData && (transformedMAData.first.length > 0 || transformedMAData.second.length > 0)) ? 58 : 10,
          events: {
            selection(event) {

              return false;
            },
            load: function () {
              // Initialize the sentiment text based on the last data point
              // Initialize the sentiment text based on the last data point
              let closeButtonIcon = darkMode ? closeButtonWhite : closeButton;
              const lastDataPoint = stockData[stockData.length - 1] || {};
              const sentiment = lastDataPoint.sentiment !== null && lastDataPoint.sentiment !== undefined ? lastDataPoint.sentiment.toFixed(2) : 'N/A';
              const sentimentColor = '#8E55FA';
              const pctSentiment = lastDataPoint.pctsentiment !== null && lastDataPoint.pctsentiment !== undefined
                  ? `${lastDataPoint.pctsentiment > 0 ? '+' : ''}${lastDataPoint.pctsentiment.toFixed(2)}%` 
                  : 'N/A';
              const pctSentimentColor = lastDataPoint.pctsentiment > 0 ? 'green' : (lastDataPoint.pctsentiment < 0 ? 'red' : (darkMode ? 'white' : 'black'));
              const sentimentText = `<span style="font-weight:bold; color:${textColor};">Sentiment</span> <span style="color: ${sentimentColor};">${sentiment}</span> <span style="color: ${pctSentimentColor};">(${pctSentiment})</span>`;
              
              this.customSentimentLabel = this.renderer.text(sentimentText, 10, 17)
                  .css({ color: '#333', fontSize: '14px' })
                  .attr({
                  })  
                  .add();
              this.customSentimentLabel.attr({
                  useHTML: true
              });
                
              let labelBBox = this.customSentimentLabel.getBBox();
              let closeButtonBg = this.renderer.rect(labelBBox.x + labelBBox.width + 25, labelBBox.y - 5, 25, 25) // Adjust size as necessary
              .attr({
                fill: 'rgba(255, 255, 255, 0)', // Initially transparent
                zIndex: -1, // Ensure it is behind the SVG button
                cursor: 'pointer'
              })
              .add();
              let svgCloseButtonX = labelBBox.x + labelBBox.width + 45 - 20; // Adjust as necessary
              let svgCloseButtonY = labelBBox.y + (labelBBox.height / 2) - 7 - 5; // Adjust as necessary, considering the size of your SVG
              let closeSVGButton = this.renderer.image(closeButtonIcon, svgCloseButtonX , svgCloseButtonY , 25, 25) // Assuming 16x16 is the size of your SVG
              .css({ cursor: 'pointer' })
              .attr({ zIndex: 2, visibility: 'hidden'})
              .add()
              .on('mouseover', () => {
                isCrossHairDisabled = true
                closeButtonBg.attr({ fill: darkMode ? "#454c5e" : "#f0f0f0" }); // Change to grey
                border.attr({ visibility: 'visible'});
                closeSVGButton.attr({ visibility: 'visible' });
              })
              .on('mouseout', () => {
                  isCrossHairDisabled = false;
                  border.attr({ visibility: 'hidden'});
                  closeSVGButton.attr({ visibility: 'hidden' });
                  closeButtonBg.attr({ fill: 'rgba(255, 255, 255, 0)' }); // Change back to transparent
              });

              // Creating an invisible hit area larger than the close button
                let hitAreaXS = closeSVGButton.attr('x'); // Slightly offset to the left for larger area
                let hitAreaYS = closeSVGButton.attr('y'); // Slightly offset upwards for larger area
                let hitAreaWidthS = 20; // Making the width larger for easier clickability
                let hitAreaHeightS = 20; // Making the height larger for easier clickability
              

              
            let buttonHitAreaS = this.renderer.rect(hitAreaXS + 0.5 , hitAreaYS + 1, hitAreaWidthS + 3, hitAreaHeightS)
              .attr({
                  fill: 'rgba(255, 255, 255, 0)', // Making the hit area invisible
                  cursor: 'pointer',
              })
              .add()
              .on('click', (e) => {

                  if (chart2) {
                      e.stopPropagation();
                      chart2.destroy();
                      chart2 = undefined;
                      handleDeleteSentiment();
                      isCrossHairDisabled = false;
                  }
              })
              .on('mouseover', () => {
                isCrossHairDisabled = true
                border.attr({ visibility: 'visible'});
                closeSVGButton.attr({ visibility: 'visible' });
                closeButtonBg.attr({ fill: darkMode ? "#454c5e" : "#f0f0f0" }); // Change background to grey
              })
              .on('mouseout', () => {
                  isCrossHairDisabled = false;
                  border.attr({ visibility: 'hidden'});
                  closeSVGButton.attr({ visibility: 'hidden' });
                  closeButtonBg.attr({ fill: 'rgba(255, 255, 255, 0)' }); // Revert background to transparent
              });
              closeButtonBg.on('mouseover', () => {
                isCrossHairDisabled = true
                border.attr({ visibility: 'visible'});
                closeButtonBg.attr({ fill: '#f0f0f0' }); // Change to grey
                closeSVGButton.attr({ visibility: 'visible' });
              }).on('mouseout', () => {
                isCrossHairDisabled = false;
                border.attr({ visibility: 'hidden'});
                closeButtonBg.attr({ fill: 'rgba(255, 255, 255, 0)' }); // Change back to transparent
                closeSVGButton.attr({ visibility: 'hidden' });
              });
            
              // Adjusting the border and hit area visibility on mouseover/mouseout
              let border = this.renderer.rect(labelBBox.x - 5, labelBBox.y - 2, labelBBox.width + closeSVGButton.getBBox().width + 30, labelBBox.height + 5, 3)
              .attr({
                stroke: darkMode ? "white" : "black",
                  'stroke-width': 1,
                  fill: 'none',
                  visibility: 'hidden',
                  zIndex: 2
              })
              .add();

              this.renderer.rect(labelBBox.x - 5, labelBBox.y - 5, labelBBox.width + closeSVGButton.getBBox().width + 35, labelBBox.height + 10)
              .attr({
                  fill: 'rgba(255, 255, 255, 0)', 
                  cursor: 'default',// Invisible hit area covering the entire label and close button
              })
              .add()
              .on('mouseover', () => {
                  isCrossHairDisabled = true
                  border.attr({ visibility: 'visible'});
                  closeSVGButton.attr({ visibility: 'visible' });
              })
              .on('mouseout', () => {
                  isCrossHairDisabled = false;
                  border.attr({ visibility: 'hidden'});
                  closeSVGButton.attr({ visibility: 'hidden' });
              });

          buttonHitAreaS.toFront(); // Ensuring the close button is visually on top
            }
                      },
        },
        title : {
          text: ''
        },
        tooltip: {
          enabled: false
        },
        sync: {
          extremes: true
        },
        crosshair: false,
        credits: {
          enabled: false,
        },
        exporting: {
          enabled: false,
        },
        navigator: {
          enabled: false,
        },
        scrollbar: {
          enabled: false,
        },
        
        rangeSelector: {
          enabled: true,
          inputEnabled: false,
          selected: 4,
        },
        series: [{
          point: {
            events: {
              mouseOver(e) {
                if (!this.series.chart) return;
                if (selectedSentiment) {
                  let point = this;
                  highlightFunction(point);}
              },
              mouseOut(e) {
                const lastDataPoint = stockData[stockData.length - 1] || {};
                const sentiment = lastDataPoint.sentiment !== null && lastDataPoint.sentiment !== undefined ? lastDataPoint.sentiment.toFixed(2) : 'N/A';
                const sentimentColor = '#8E55FA';
                const pctSentiment = lastDataPoint.pctsentiment !== null && lastDataPoint.pctsentiment !== undefined
                    ? `${lastDataPoint.pctsentiment > 0 ? '+' : ''}${lastDataPoint.pctsentiment.toFixed(2)}%` 
                    : 'N/A';
                const pctSentimentColor = lastDataPoint.pctsentiment > 0 ? 'green' : (lastDataPoint.pctsentiment < 0 ? 'red' : (darkMode ? 'white' : 'black'));
                const sentimentText = `<span style="font-weight:bold; color: ${textColor}">Sentiment</span> <span style="color: ${sentimentColor};">${sentiment}</span> <span style="color: ${pctSentimentColor};">(${pctSentiment})</span>`;
                chart2.customSentimentLabel.attr({
                  text:  sentimentText
                });
                const exchange = companyExchanges[searchTerm]?.market || 'N/A';
                const lastDataPointPrice = (resolution !== "Daily" ? stockData : priceData)[(resolution !== "Daily" ? stockData : priceData).length - 1] || {};
                const close = lastDataPointPrice.close ? lastDataPointPrice.close.toFixed(2) : 'N/A';
                const clschange = lastDataPointPrice.clschange !== null && lastDataPointPrice.clschange !== undefined ? lastDataPointPrice.clschange.toFixed(2) : 'N/A';
                const clschangeColor = lastDataPointPrice.clschange > 0 ? 'green' : (lastDataPointPrice.clschange < 0 ? 'red' : (darkMode ? 'white' : 'black'));
                const pctclschange = lastDataPointPrice.pctclschange !== null && lastDataPointPrice.pctclschange !== undefined
                  ? `${lastDataPointPrice.pctclschange > 0 ? '+' : ''}${(lastDataPointPrice.pctclschange).toFixed(2)}%` 
                  : 'N/A';
                  const pctclschangeColor = lastDataPointPrice.pctclschange > 0 ? 'green' : (lastDataPointPrice.pctclschange < 0 ? 'red' : (darkMode ? 'white' : 'black'));
   
              // Construct the label with HTML styling for the bold part  
                const labelText = `<span style="font-weight:bold ; color: ${textColor};">${searchTerm} | ${exchange} | </span>` +
                `<span style="color: ${textColor};">Close ${close} <span style="color: ${clschangeColor};">${clschange}</span> <span style="color: ${pctclschangeColor};">(${pctclschange})</span></span>`;
                chart1.customLabel.attr({
                  text: labelText
                });
                if (transformedMAData && (transformedMAData.first.length > 0)) {
                  const initialMAFirst = transformedMAData.first[transformedMAData.first.length - 1];
                  const maTypeTextFirst = firstMaType === 'simple' ? 'SMA' : firstMaType === 'exponential' ? 'EMA' : 'MA';
                  const maFirstText = `<span style="font-weight:bold; color: ${textColor};">Sentiment ${maTypeTextFirst} ${firstMaPeriod}</span> <span style="color: #2CAFFE;">${initialMAFirst.sentiment.toFixed(2)}</span>`;
                  chart1.maFirstLabel.attr({ text: maFirstText });
                }
                if (transformedMAData && (transformedMAData.second.length > 0)) {
                  const initialMASecond = transformedMAData.second[transformedMAData.second.length - 1];
                  const maTypeTextSecond = secondMaType === 'simple' ? 'SMA' : secondMaType === 'exponential' ? 'EMA' : 'MA';
                  const maSecondText = `<span style="font-weight:bold; color: ${textColor}; ">Sentiment ${maTypeTextSecond} ${secondMaPeriod}</span> <span style="color: orange;">${initialMASecond.sentiment.toFixed(2)}</span>`;
                  chart1.maSecondLabel.attr({ text: maSecondText });
                }
                if (selectedAttentionBuzz) {
                  const lastDataPoint = stockData[stockData.length - 1] || {};
                  const attentionBuzz = lastDataPoint.attention_buzz !== null && lastDataPoint.attention_buzz !== undefined ? lastDataPoint.attention_buzz.toFixed(2) : 'N/A';
                  const attentionBuzzColor = "#2CAFFE";
                  const pctBuzz = lastDataPoint.pctbuzz !== null && lastDataPoint.pctbuzz !== undefined
                    ? `${lastDataPoint.pctbuzz > 0 ? '+' : ''}${lastDataPoint.pctbuzz.toFixed(2)}` 
                    : 'N/A';
                  const pctBuzzColor = lastDataPoint.pctbuzz > 0 ? 'green' : (lastDataPoint.pctbuzz < 0 ? 'red' : (darkMode ? 'white' : 'black'));
                  const buzzText = `<span style="font-weight:bold; color: ${textColor}">Attention Buzz</span> <span style="color: ${attentionBuzzColor};">${attentionBuzz}</span> <span style="color: ${pctBuzzColor};">(${pctBuzz})</span>`;
                  chart3.customBuzzLabel.attr({
                    text: buzzText
                  });
                }
              }
            }
          },
          enabledCrosshairs: true,
          data:  stockData.map(data => [new Date(data.published_date).getTime(), data.sentiment]),
          lastVisiblePrice: {
            enabled: true,
            label: {
              padding: 3,
              format: '{value:.2f}',
              enabled: true,
              backgroundColor: textColor ,
              shape: 'rect',
              style: {
                color: textColorInvert,
                fontWeight: 'normal',
                fontSize: '	0.8em', // Set your desired font size here
                textAlign: 'center'
              }
            }
          },
          
          color: "#8E55FA",
          name: 'sentiment',
          connectNulls: true,
          lineWidth: 1.5, // Set the line thickness to 3 pixels 
        }],
      });
  
    chart3 = Highcharts.StockChart('container3', {
      // Configuration for chart2
      xAxis: {
        crosshair: false,
        type: 'datetime',
        visible: showXAxisOnAttentionBuzzOnly || showXAxisOnBoth,
        lineColor: darkMode ? "#FFFFFF" : "#000000",
        tickColor: darkMode ? "#FFFFFF" : "#000000",
        labels: {
          style:{
            color: darkMode ? '#FFFFFF' : '#000000',
          }
        },
        events: {
          setExtremes: function(e) {
            if (!modifying) {
              modifying = true;
              chart1.xAxis[0].setExtremes(e.min, e.max, true, false);
              chart2.xAxis[0].setExtremes(e.min, e.max, true, false);
              modifying = false;
            }
          }
        },
      },
      tooltip: {
        shared: true,
        formatter: function() {
          return false;
        }
      },
      chart: {
        animation: false,  // Disable animation to reduce load
        backgroundColor: darkMode ? '#000000' : '#FFFFFF',
        height: chartHeights.chart3, 
        type: 'column',
        panning: {
          enabled: true,
          type: 'x'
        },
        zooming: {
          mouseWheel: {
              enabled: false,
          }
        },
        style: {
          fontFamily: 'Lato' // Apply Lato font
        },
        marginRight:42,
        marginLeft: (transformedMAData && (transformedMAData.first.length > 0 || transformedMAData.second.length > 0)) ? 57 : 10,
        events: {
          selection(event) {
            return false;
          },
          load: function () {
            // Initial label setup for chart3
            let closeButtonIcon = darkMode ? closeButtonWhite : closeButton;
            const lastDataPoint = stockData[stockData.length - 1] || {};
            const attentionBuzz = lastDataPoint.attention_buzz !== null && lastDataPoint.attention_buzz !== undefined ? lastDataPoint.attention_buzz.toFixed(2) : 'N/A';
            const attentionBuzzColor = "#2CAFFE";
            const pctBuzz = lastDataPoint.pctbuzz !== null && lastDataPoint.pctbuzz !== undefined
                ? `${lastDataPoint.pctbuzz > 0 ? '+' : ''}${lastDataPoint.pctbuzz.toFixed(2)}` 
                : 'N/A';
            const pctBuzzColor = lastDataPoint.pctbuzz > 0 ? 'green' : (lastDataPoint.pctbuzz < 0 ? 'red' : (darkMode ? 'white' : 'black'));
            const buzzText = `<span style="font-weight:bold; color:${textColor};">Attention Buzz</span> <span style="color: ${attentionBuzzColor};">${attentionBuzz}</span> <span style="color: ${pctBuzzColor};">(${pctBuzz})</span>`;
            this.customBuzzLabel = this.renderer.text(buzzText, 10, 17)
                .css({ color: '#333', fontSize: '14px' })
                .attr({
                })
                .add();
            this.customBuzzLabel.attr({
                useHTML: true
            });

            let labelABox = this.customBuzzLabel.getBBox();
            let closeAButtonBg = this.renderer.rect(labelABox.x + labelABox.width + 20, labelABox.y - 5, 25, 25) // Adjust size as necessary
              .attr({
                fill: 'rgba(255, 255, 255, 0)', // Initially transparent
                zIndex: -1, // Ensure it is behind the SVG button
                cursor: 'pointer'
              })
              .add();
              let svgACloseButtonX = labelABox.x + labelABox.width + 45 -5 ; // Adjust as necessary
              let svgACloseButtonY = labelABox.y + (labelABox.height / 2) - 7 - 5; // Adjust as necessary, considering the size of your SVG
              let closeSVGAButton = this.renderer.image(closeButtonIcon, svgACloseButtonX - 20, svgACloseButtonY, 25, 25) // Assuming 16x16 is the size of your SVG
              .css({ cursor: 'pointer' })
              .attr({ zIndex: 3, visibility: 'hidden'})
              .add()
              .on('mouseover', () => {
                isCrossHairDisabled = true
                closeAButtonBg.attr({ fill: darkMode ? "#454c5e" : "#f0f0f0" }); // Change to grey
                border.attr({ visibility: 'visible' });
                closeSVGAButton.attr({ visibility: 'visible' });
              })
              .on('mouseout', () => {
                  isCrossHairDisabled = false;
                  border.attr({ visibility: 'hidden' });
                  closeSVGAButton.attr({ visibility: 'hidden' });
                  closeAButtonBg.attr({ fill: 'rgba(255, 255, 255, 0)' }); // Change back to transparent
              });

            // Creating an invisible hit area larger than the close button
            let hitAreaX = closeSVGAButton.attr('x'); // Slightly offset to the left for larger area
            let hitAreaY = closeSVGAButton.attr('y'); // Slightly offset upwards for larger area
            let hitAreaWidth = 20; // Making the width larger for easier clickability
            let hitAreaHeight = 20; // Making the height larger for easier clickability

            let buttonHitArea = this.renderer.rect(hitAreaX + 0.5, hitAreaY + 1, hitAreaWidth + 3, hitAreaHeight)
                .attr({
                    fill: 'rgba(255, 255, 255, 0)', // Making the hit area invisible
                    cursor: 'pointer',
                })
                .add()
                .on('click', (e) => {
                    if (chart3) {
                        e.stopPropagation();
                        chart3.destroy();
                        chart3 = undefined;
                        handleDeleteAttentionBuzz();
                    }
                })
                .on('mouseover', () => {
                  isCrossHairDisabled = true
                  border.attr({ visibility: 'visible' });
                  closeSVGAButton.attr({ visibility: 'visible' });
                  closeAButtonBg.attr({ fill: darkMode ? "#454c5e" : "#f0f0f0"  });
              })
              .on('mouseout', () => {
                  isCrossHairDisabled = false;
                  border.attr({ visibility: 'hidden' });
                  closeSVGAButton.attr({ visibility: 'hidden' });
                  closeAButtonBg.attr({ fill: 'rgba(255, 255, 255, 0)' }); // Revert background to transparent
              });
              closeAButtonBg.on('mouseover', () => {
                isCrossHairDisabled = true
                border.attr({ visibility: 'visible' });
                closeAButtonBg.attr({ fill: '#f0f0f0' }); // Change to grey
                closeSVGAButton.attr({ visibility: 'visible' });
              }).on('mouseout', () => {
                isCrossHairDisabled = false;
                border.attr({ visibility: 'hidden' });
                closeAButtonBg.attr({ fill: 'rgba(255, 255, 255, 0)' }); // Change back to transparent
                closeSVGAButton.attr({ visibility: 'hidden' });
              });
            
            // Adjusting border and hit area visibility logic
            let border = this.renderer.rect(labelABox.x - 5, labelABox.y - 2, labelABox.width + closeSVGAButton.getBBox().width + 25, labelABox.height + 5, 3)
                .attr({
                  stroke: darkMode ? "white" : "black",
                    'stroke-width': 1,
                    fill: 'none',
                    visibility: 'hidden',
                    zIndex: 2,
                })
                .add();

            this.renderer.rect(labelABox.x - 5, labelABox.y - 5, labelABox.width + closeSVGAButton.getBBox().width + 35, labelABox.height + 10)
                .attr({
                    fill: 'rgba(255, 255, 255, 0)',
                    cursor: 'default', // Invisible hit area covering the entire label and close buttonW
                })
                .add()
                .on('mouseover', () => {
                    isCrossHairDisabled = true

                    border.attr({ visibility: 'visible' });
                    closeSVGAButton.attr({ visibility: 'visible' });
                })
                .on('mouseout', () => {
                    isCrossHairDisabled = false;

                    border.attr({ visibility: 'hidden' });
                    closeSVGAButton.attr({ visibility: 'hidden' });
                });

            buttonHitArea.toFront(); // Ensuring the close button is visually on top
          },
        },
      },
      title : {
        text: ''
      },
      credits: {
        enabled: false,
      },
      exporting: {
        enabled: false,
      },
      navigator: {
        enabled: false,
      },
      scrollbar: {
        enabled: false,
      },
      crosshair: false,
      rangeSelector: {
          enabled: true,
          inputEnabled: false,
          selected: 4,
        },
      sync: {
        extremes: true
      },
      plotOptions: {
        column: {
          //pointPadding: 5,
          //borderWidth: 1,
          dataGrouping: {
            enabled:false,
          },
          grouping: false
        },
        series: {
          pointPlacement: "on"
        }
      },
      yAxis: {
        crosshair: false,
        title:{
          enabled: false,
        },
        offset: 25,
        gridLineColor: darkMode ? "#2a2e39" : "#E6E6E6",
        left: (transformedMAData.first.length || transformedMAData.second.length) ? 90 : 45,
        labels: {
          x: -38,
          style:{
            color: darkMode ? '#FFFFFF' : '#000000',
          }
        },
        opposite: true, // Original Y-axis on the right
        showLastLabel: true,
      },
      series: [{
        showInLegend: false, 
        point: {
          events: {
            mouseOver(e) {
              let point = this;
              highlightFunction(point);
            },
            mouseOut(e) {
              const lastDataPoint = stockData[stockData.length - 1] || {};
              const attentionBuzz = lastDataPoint.attention_buzz !== null && lastDataPoint.attention_buzz !== undefined ? lastDataPoint.attention_buzz.toFixed(2) : 'N/A';
              const attentionBuzzColor = "#2CAFFE";
              const pctBuzz = lastDataPoint.pctbuzz !== null && lastDataPoint.pctbuzz !== undefined
                ? `${lastDataPoint.pctbuzz > 0 ? '+' : ''}${lastDataPoint.pctbuzz.toFixed(2)}` 
                : 'N/A';
              const pctBuzzColor = lastDataPoint.pctbuzz > 0 ? 'green' : (lastDataPoint.pctbuzz < 0 ? 'red' : (darkMode ? 'white' : 'black'));
              const buzzText = `<span style="font-weight:bold; color: ${textColor};">Attention Buzz</span> <span style="color: ${attentionBuzzColor};">${attentionBuzz}</span> <span style="color: ${pctBuzzColor};">(${pctBuzz})</span>`;
              chart3.customBuzzLabel.attr({
                text: buzzText
              });
              if (selectedSentiment) {
                const lastDataPoint = stockData[stockData.length - 1] || {};
                const sentiment = lastDataPoint.sentiment !== null && lastDataPoint.sentiment !== undefined ? lastDataPoint.sentiment.toFixed(2) : 'N/A';
                const sentimentColor = '#8E55FA';
                const pctSentiment = lastDataPoint.pctsentiment !== null && lastDataPoint.pctsentiment !== undefined
                    ? `${lastDataPoint.pctsentiment > 0 ? '+' : ''}${lastDataPoint.pctsentiment.toFixed(2)}%` 
                    : 'N/A';
                const pctSentimentColor = lastDataPoint.pctsentiment > 0 ? 'green' : (lastDataPoint.pctsentiment < 0 ? 'red' : (darkMode ? 'white' : 'black'));
                const sentimentText = `<span style="font-weight:bold; color: ${textColor};">Sentiment</span> <span style="color: ${sentimentColor};">${sentiment}</span> <span style="color: ${pctSentimentColor};">(${pctSentiment})</span>`;
                chart2.customSentimentLabel.attr({
                  text:  sentimentText
                });
              }
              if (transformedMAData && (transformedMAData.first.length > 0)) {
                const initialMAFirst = transformedMAData.first[transformedMAData.first.length - 1];
                const maTypeTextFirst = firstMaType === 'simple' ? 'SMA' : firstMaType === 'exponential' ? 'EMA' : 'MA';
                const maFirstText = `<span style="font-weight:bold; color: ${textColor};">Sentiment ${maTypeTextFirst} ${firstMaPeriod}</span> <span style="color: #2CAFFE;">${initialMAFirst.sentiment.toFixed(2)}</span>`;
                chart1.maFirstLabel.attr({ text: maFirstText });
              }
              if (transformedMAData && (transformedMAData.second.length > 0)) {
                const initialMASecond = transformedMAData.second[transformedMAData.second.length - 1];
                const maTypeTextSecond = secondMaType === 'simple' ? 'SMA' : secondMaType === 'exponential' ? 'EMA' : 'MA';
                const maSecondText = `<span style="font-weight:bold; color: ${textColor};">Sentiment ${maTypeTextSecond} ${secondMaPeriod}</span> <span style="color: orange;">${initialMASecond.sentiment.toFixed(2)}</span>`;
                chart1.maSecondLabel.attr({ text: maSecondText });
              }
              const exchange = companyExchanges[searchTerm]?.market || 'N/A';
                const lastDataPointPrice = (resolution !== "Daily" ? stockData : priceData)[(resolution !== "Daily" ? stockData : priceData).length - 1] || {};
                const close = lastDataPointPrice.close ? lastDataPointPrice.close.toFixed(2) : 'N/A';
                const clschange = lastDataPointPrice.clschange !== null && lastDataPointPrice.clschange !== undefined ? lastDataPointPrice.clschange.toFixed(2) : 'N/A';
                const clschangeColor = lastDataPointPrice.clschange > 0 ? 'green' : (lastDataPointPrice.clschange < 0 ? 'red' : (darkMode ? 'white' : 'black'));
                const pctclschange = lastDataPointPrice.pctclschange !== null && lastDataPointPrice.pctclschange !== undefined
                  ? `${lastDataPointPrice.pctclschange > 0 ? '+' : ''}${(lastDataPointPrice.pctclschange).toFixed(2)}%` 
                  : 'N/A';
                  const pctclschangeColor = lastDataPointPrice.pctclschange > 0 ? 'green' : (lastDataPointPrice.pctclschange < 0 ? 'red' : (darkMode ? 'white' : 'black'));
   
              // Construct the label with HTML styling for the bold part  
                const labelText = `<span style="font-weight:bold; color: ${textColor};">${searchTerm} | ${exchange} | </span>` +
                `<span style="color: ${textColor};">Close ${close} <span style="color: ${clschangeColor};">${clschange}</span> <span style="color: ${pctclschangeColor};">(${pctclschange})</span></span>`;
                chart1.customLabel.attr({
                  text: labelText
                });
            }
          }
        },
        data: stockData.map(data => [new Date(data.published_date).getTime(), Number(data.attention_buzz)]),
        enabledCrosshairs: true,
        lastVisiblePrice: {
          enabled: true,
          label: {
            padding: 3,
            format: '{value:.2f}',
            enabled: true,
            backgroundColor: "#2CAFFE",
            shape: 'rect',
            style: {
              color: 'white',
              fontWeight: 'normal',
              fontSize: '	0.8em', // Set your desired font size here
              textAlign: 'center'
            }
          }
        },
        type:'column',
        connectNulls:true,
        name: 'attention_buzz',
        lineWidth: 1.5, // Set the line thickness to 3 pixels 
      }],
    });

  };

  function adjustZoom(chart, event, otherCharts) {
    if (chart && chart.xAxis && chart.xAxis[0]) {
        event.preventDefault(); // Prevent the default action to avoid scrolling the page

        const extremes = chart.xAxis[0].getExtremes();
        const zoomFactor = event.deltaY > 0 ? 1.1 : 0.9; // Adjust zoom direction
        let newRange = (extremes.max - extremes.min) * zoomFactor;

        // Find the last visible data point
        const visibleData = chart.series[0].data.filter(point => point.x >= extremes.min && point.x <= extremes.max);
        const lastVisibleDataPoint = visibleData.length > 0 ? visibleData[visibleData.length - 1].x : extremes.max; // Use the last point or current max

        // Calculate new extremes based on zoom
        let newMax = lastVisibleDataPoint;
        let newMin = newMax - newRange;

        // Adjust if newMin is less than the minimum data point
        if (newMin < extremes.dataMin) {
            newMin = extremes.dataMin;
            newMax = newMin + newRange; // Adjust newMax to maintain the new range
        }

        // Ensure the newMax does not extend beyond the last data point in the dataset
        if (newMax > chart.series[0].xData[chart.series[0].xData.length - 1]) {
            newMax = chart.series[0].xData[chart.series[0].xData.length - 1];
            newMin = newMax - newRange; // Recalculate newMin to ensure the range stays consistent
            // Further ensure newMin does not fall below dataMin
            if (newMin < extremes.dataMin) {
                newMin = extremes.dataMin;
            }
        }

        // Set new extremes for the current chart
        chart.xAxis[0].setExtremes(newMin, newMax, false);

        // Synchronize other charts
        otherCharts.forEach((otherChart) => {
            if (otherChart && otherChart.xAxis && otherChart.xAxis[0]) {
                otherChart.xAxis[0].setExtremes(newMin, newMax, false);
            }
        });

        // Redraw all charts to apply the changes
        chart.redraw();
        otherCharts.forEach((otherChart) => otherChart.redraw());
    }
}
        // Attach event listener for 'container' (chart1)
        document.getElementById('container').addEventListener('wheel', (event) => {
            adjustZoom(chart1, event, [chart2, chart3]);
        });
        
        // Attach event listener for 'container2' (chart2)
        document.getElementById('container2').addEventListener('wheel', (event) => {
            adjustZoom(chart2, event, [chart1, chart3]);
        });
        
        // Attach event listener for 'container3' (chart3)
        document.getElementById('container3').addEventListener('wheel', (event) => {
            adjustZoom(chart3, event, [chart1, chart2]);
        });


    

      // Create chart1 with your data
      

    if (Highcharts && stockData) {
      createCharts(stockData);
    }
    function synchronizeCrosshairs(e) {
      if (!isCrosshairActive) return;
      const currentChart = this;
      const charts = [chart1, chart2, chart3];
      const closestPoint = currentChart.series[0].searchPoint(e, true);
      const isChart1Visible = chartHeights.chart1 !== '0%'; // Assuming chartHeights state controls visibility
      const isChart2Visible = chartHeights.chart2 !== '0%';
      const isChart3Visible = chartHeights.chart3 !== '0%';
      // Only include charts in the array that are visible based on your visibility logic
      const visibleCharts = [];
      if (isChart1Visible) visibleCharts.push(chart1);
      if (isChart2Visible) visibleCharts.push(chart2);
      if (isChart3Visible) visibleCharts.push(chart3);
      const lastVisibleChart = visibleCharts[visibleCharts.length - 1]; // Get the last visible chart
      if (!closestPoint) return;
      let pointX = closestPoint.plotX + currentChart.plotLeft;
      const labelTextX = Highcharts.dateFormat('%a %d %b %y', closestPoint.x);
  
      charts.forEach(chart => {
          if (chart.verticalCrosshair || isCrossHairDisabled) chart.verticalCrosshair.destroy();
          if (chart.xCrossLabel || isCrossHairDisabled) chart.xCrossLabel.destroy();
          // Synchronize vertical crosshairs

          chart.verticalCrosshair = chart.renderer.path(['M', pointX, chart.plotTop, 'L', pointX, chart.plotTop + chart.plotHeight])
              .attr({dashStyle: 'ShortDot', stroke: textColor, zIndex: 9})
              .add();

            if (chart === lastVisibleChart && (isCrossHairDisabled == false)) { // Check if the current chart is the last visible chart
                chart.xCrossLabel = chart.renderer.label(labelTextX, pointX - 35, chart.plotHeight + chart.plotTop, 'callout')
                    .css({color: textColorInvert, fontSize: '12px'})
                    .attr({fill: textColor, padding: 4, r: 5, zIndex: 9})
                    .add();
            }
      });
  
      // Handle horizontal crosshair and Y value label for current chart
      const mouseY = e.chartY ;
      if (mouseY > currentChart.plotTop && mouseY < currentChart.plotTop + currentChart.plotHeight) {
          if (currentChart.horizontalCrosshair) currentChart.horizontalCrosshair.destroy();
          if (currentChart.crossLabel) currentChart.crossLabel.destroy();
  
          currentChart.horizontalCrosshair = currentChart.renderer.path(['M', currentChart.plotLeft, mouseY, 'L', currentChart.plotLeft + currentChart.plotWidth , mouseY])
              .attr({stroke: textColor, zIndex: -1, dashStyle: 'Dot'})
              .add();
  
              const labelTextY = currentChart.yAxis[0].toValue(mouseY).toFixed(2);
              currentChart.crossLabel = currentChart.renderer.label(
                `<span style="white-space: nowrap; text-align: center; width: 33.5px; height: 10px; display: inline-block;">${labelTextY}</span>`, 
                currentChart.plotLeft + currentChart.plotWidth + 2, 
                mouseY - 13, 
                'rect',
                null,
                null,
                true,
              ).css({
                color: textColorInvert, 
                fontSize: '0.8em',
              }).attr({
                fill: textColor, 
                padding: 3, 
                r: 4, 
                zIndex: 9, 
                dashStyle: 'ShortDot'
                
              }).add();
              if (currentChart.yAxis.length > 1 && transformedMAData.first.length > 0) {
              const valueForSecondAxis = currentChart.yAxis[1].toValue(mouseY).toFixed(2);

              // Check if a label for the second Y-axis already exists and destroy it if it does
              if (currentChart.secondYAxisLabel) {
                  currentChart.secondYAxisLabel.destroy();
              }
  
              // Create a new label for the second Y-axis value
              currentChart.secondYAxisLabel = currentChart.renderer.label(
                  `<span style="white-space: nowrap; text-align: center; width: 33.5px; height: 10px; display: inline-block;">${valueForSecondAxis}</span>`,
                  currentChart.plotLeft - 40, // Adjust the position based on your layout
                  mouseY - 13,
                  'rect',
                  null,
                  null,
                  true,
              ).css({
                  color: textColorInvert,
                  fontSize: '0.8em',
              }).attr({
                  fill: textColor,
                  padding: 3,
                  r: 4,
                  zIndex: 9,
                  dashStyle: 'ShortDot'
              }).add();
              }
      }
  }
  function destroyCrosshairs(chart) {
    if (chart.horizontalCrosshair) {
        chart.horizontalCrosshair.destroy();
        chart.horizontalCrosshair = null;
    }
    if (chart.verticalCrosshair) {
        chart.verticalCrosshair.destroy();
        chart.verticalCrosshair = null;
    }
    if (chart.crossLabel) {
        chart.crossLabel.destroy();
        chart.crossLabel = null;
    }
    if (chart.xCrossLabel) {
        chart.xCrossLabel.destroy();
        chart.xCrossLabel = null;
    }
    if (chart.secondYAxisLabel) {
      chart.secondYAxisLabel.destroy();
      chart.secondYAxisLabel = null;
  }
}
  function setupCrosshairSynchronization() {
    const charts = [chart1, chart2, chart3]; // Ensure these are correctly initialized elsewhere

    charts.forEach(chart => {
        Highcharts.addEvent(chart.container, 'mousemove', function(e) {
            if (isCrossHairDisabled) {
                charts.forEach(destroyCrosshairs); // Apply to all charts
                // Optionally, reset isCrossHairDisabled here or elsewhere as needed

            } else if (isCrosshairActive) {
                synchronizeCrosshairs.call(chart, e);
            }
        });

        Highcharts.addEvent(chart.container, 'mouseleave', function() {
            if (isCrosshairActive) {
              charts.forEach(destroyCrosshairs); // Apply to all charts
            }
        });
    });
    isCrossHairDisabled = false;
}


  setupCrosshairSynchronization();

    return () => {
      if (chart1) {
        chart1.destroy();
      }
      if (chart2) {
        chart2.destroy();
      }
      if (chart3){
        chart3.destroy();
      }
    };
  }, [stockData, selectedSentiment, selectedAttentionBuzz, searchTerm, companyExchanges, transformedMAData, handleDeleteSentiment, handleDeleteAttentionBuzz]);

  return (
    <div style={{ display: 'flex', flexDirection: 'column'}}>
      <div id="container" ref={chart1Ref}style={{ width: '100%', display: chartHeights.chart1 !== '0%' ? 'block' : 'none' }}></div>
      <div id="container2" style={{ width: '100%', display: chartHeights.chart2 !== '0%' ? 'block' : 'none' }}></div>
      <div id="container3" style={{ width: '100%', display: chartHeights.chart3 !== '0%' ? 'block' : 'none' }}></div>
    </div>
  );
};

const CrosshairSynchronizationChartMemo = React.memo(CrosshairSynchronizationChart, (prevProps, nextProps) => {
  // This is a shallow comparison; depending on your data, you might need a deeper comparison
  // For complex objects or arrays, consider using lodash's isEqual for deep comparison
  return prevProps.selectedSentiment === nextProps.selectedSentiment &&
         prevProps.selectedAttentionBuzz === nextProps.selectedAttentionBuzz &&
         prevProps.transformedMAData === nextProps.transformedMAData &&
         prevProps.maConfigs === nextProps.maConfigs &&
         prevProps.searchTerm === nextProps.searchTerm &&
         prevProps.isCrosshairActive === nextProps.isCrosshairActive &&
         prevProps.separatorMovedCounter == nextProps.separatorMovedCounter &&
         prevProps.darkMode == nextProps.darkMode; 
});

export default CrosshairSynchronizationChartMemo;
