import React, { useState, useRef, useEffect} from "react";
import "./IndicatorSettingsPanel.css";

const IndicatorSettingsPanel = ({ onClose, onApply, darkMode}) => {
  const [maType, setMaType] = useState("simple");
  const [period, setPeriod] = useState("");
  const [overlayWidth, setOverlayWidth] = useState(0); // State to hold the overlay width
  const containerRef = useRef(null); // Ref for the input container

    const [isFocused, setIsFocused] = useState(false)
  const containerFocusStyles = {
    border: darkMode? "2px solid #936BC3" : "2px solid #936BC3",
    outline: 'none' // Remove default browser outline
  };
  useEffect(() => {
    const updateOverlayWidth = () => {
      const panel = document.querySelector('.indicator-panel');
      if (panel) {
        const panelWidth = panel.getBoundingClientRect().width; // More accurate width
        setOverlayWidth(panelWidth * 1.05);
      }
    };

    updateOverlayWidth(); // Update width on mount

    const resizeObserver = new ResizeObserver(updateOverlayWidth); // Observe resize
    resizeObserver.observe(document.body); // Assume panel width changes might reflect a body resize

    return () => {
      resizeObserver.disconnect(); // Cleanup
    };
}, []);
  const handleMaTypeChange = (event) => {
    setMaType(event.target.value);
  };

  const handlePeriodChange = (event) => {
    setPeriod(event.target.value);
  };

  const handleApplyClick = () => {
    if (onApply) onApply(maType, parseInt(period, 10)); // Ensure period is sent as a number
    onClose();
  };

  const handleSubmit = (event) => {
    event.preventDefault(); // Prevent the form from causing a page refresh
    handleApplyClick();
  };
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (containerRef.current && !containerRef.current.contains(event.target)) {
        setIsFocused(false); // Remove focus when clicking outside of the container
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  return (
    <div className="indicator-settings-overlay" style={{ width: `${overlayWidth}px` }}>
      <div 
      className={`indicator-settings-panel ${darkMode ? 'dark-mode' : ''}`} style={{
              backgroundColor: darkMode ? '#000000' : '#FFFFFF', // White when darkMode is true, otherwise black
              color: darkMode ? '#FFFFFF' : '#000000', // Black text when darkMode is true, otherwise white
              boxShadow: darkMode ? "0px 0px 4px #2a2e39" : "0px 0px 8px rgba(0, 0, 0, 0.1)",
              }}>
        <div className="edit-indicator-title" style={{
              backgroundColor: darkMode ? '#454c5e' : '#000000', // White when darkMode is true, otherwise black
              color: darkMode ? '#FFFFFF' : '#FFFFFF',
              border: darkMode ? '#FFFFFF' : '#000000' // Black text when darkMode is true, otherwise white
              }}>Edit Moving Average</div>
        <form onSubmit={handleSubmit}> {/* Here, the form's submit action is handled */}
          <div className="parameters-title">Parameters</div>
          <div className="ma-type-container">
            <label htmlFor="maType">MA Type</label>
            <div className="dropdown-container" style={{
              backgroundColor: darkMode ? '#000000' : '#FFFFFF', // White when darkMode is true, otherwise black
              color: darkMode ? '#FFFFFF' : '#000000', // Black text when darkMode is true, otherwise white
              }}>
              <select id="maType" value={maType} onChange={handleMaTypeChange}>
                <option value="simple" style={{
              backgroundColor: darkMode ? '#000000' : '#FFFFFF', // White when darkMode is true, otherwise black
              color: darkMode ? '#FFFFFF' : '#000000', // Black text when darkMode is true, otherwise white
              }}>Simple</option>
                <option value="exponential" style={{
              backgroundColor: darkMode ? '#000000' : '#FFFFFF', // White when darkMode is true, otherwise black
              color: darkMode ? '#FFFFFF' : '#000000', // Black text when darkMode is true, otherwise white
              }}>Exponential</option>
              </select>
            </div>
          </div>
          <div className="ma-period-container">
            <label htmlFor="maPeriod">Period</label>
            <div ref = {containerRef} className={`input-container ${darkMode ? 'dark-mode' : 'light-mode'}`}  style={{
              color: darkMode ? '#000000' : '#000000', // Black text when darkMode is true, otherwise white
              backgroundColor: darkMode ? "#000000" : "#FFFFFF",
              ...(isFocused ? containerFocusStyles : {}),
              }} >
              <input
                type="number"
                id="maPeriod"
                value={period}
                onChange={handlePeriodChange}
                min="1"
                max="200"
                color={darkMode ? '#000000' : '#FFFFFF'}
                className={`input ${darkMode ? 'dark-mode' : 'light-mode'}`}
                style={{
                  color: darkMode ? '#FFFFFF' : '#000000',

                }}

                onFocus={() => setIsFocused(true)}
                onBlur={() => setIsFocused(false)}
              />
            </div>
          </div>
          <div className="indicator-box-apply-buttons">
            <div className="indicator-apclose-buttons">
              <button type="button" onClick={onClose} className={`close-button ${darkMode ? 'dark-mode' : 'light-mode'}`}>
                Close
              </button>
              <button type="submit" className={`apply-button ${darkMode ? 'dark-mode' : 'light-mode'}`} > {/* Changing this to a submit button */}
                Apply
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default IndicatorSettingsPanel;