import React, { useRef, useState, useEffect } from 'react';
import { AiOutlineSearch } from 'react-icons/ai';
import { FaMagnifyingGlass } from "react-icons/fa6";
import { PiMagnifyingGlassLight } from "react-icons/pi";

import './SearchBar.css';

const SearchBar = ({
  searchTerm,
  setSearchTerm,
  currentPrice,
  sentimentScore,
  sentimentChange,
  attentionBuzz,
  onSearch,
  sentimentScoreStyle,
  sentimentChangeStyle,
  attenBuzzChangeStyle,
  darkMode
}) => {
  const [inputValue, setInputValue] = useState(searchTerm);
  const [isFocused, setIsFocused] = useState(false);
  const inputRef = useRef(null);

  const defaultPadding = searchTerm ? '0 4px' : '0 10px 0 50%';
  const [padding, setPadding] = useState(defaultPadding);
  useEffect(() => {
    // Adjust padding based on focus state and if there's a searchTerm
    const newPadding = isFocused || !searchTerm ? '0 10px 0 50%' : '0 4px';
    setPadding(newPadding);
  }, [isFocused, searchTerm]);
  const handleFocus = () => {
    setIsFocused(true);
    setInputValue('');
  };

  const handleBlur = () => {
    setIsFocused(false);
    if (!inputValue) {
      setInputValue(searchTerm);
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const capitalizedInput = inputValue.toUpperCase(); // Convert the input value to uppercase
    onSearch(capitalizedInput); // Pass the capitalized input to the search handler
    setIsFocused(false);
    if (setSearchTerm) setSearchTerm(capitalizedInput); // Update the searchTerm with the capitalized value
    inputRef.current.blur();
  };

  const handleChange = (e) => {
    setInputValue(e.target.value);
  };
  useEffect(() => {
    const focusSearchInput = (event) => {
      const ignoreKeys = ['Tab', 'ArrowLeft', 'ArrowRight', 'ArrowUp', 'ArrowDown', 'Control', 'Alt', 'Meta', 'Shift'];
      if (ignoreKeys.includes(event.key)) return;

      const activeElement = document.activeElement;
      const inputs = ['input', 'textarea', 'select', '[contenteditable="true"]'];
      const shouldIgnore = inputs.some(selector => activeElement.matches(selector));
      if (!shouldIgnore) {
        inputRef.current.focus();
      }
    };

    window.addEventListener('keydown', focusSearchInput);

    return () => window.removeEventListener('keydown', focusSearchInput);
  }, []);
  const handleClickSpan = () => {
    if(inputRef.current) {
      inputRef.current.focus();
    }
  };
  const handleIconClick = () => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  return (
    <div className="search-bar-container"style={{
      backgroundColor: darkMode ? '#232323' : '#f5f5f5', // White when darkMode is true, otherwise black
      }}>
  <PiMagnifyingGlassLight  className="search-icon" style={{ color: darkMode ? '#FFFFFF' : '#333' }}/>
  <form onSubmit={handleSubmit} className="search-form">
    <input
      ref={inputRef}
      className="search-input"
      type="text"
      value={isFocused ? inputValue : searchTerm || inputValue}
      onChange={handleChange}
      onFocus={handleFocus}
      onBlur={handleBlur}
      placeholder={isFocused || !searchTerm ? 'Enter Symbol' : ''}
      style={{ textAlign: isFocused ? 'left' : 'right', padding: padding, color: darkMode ? "#FFFFFF": "#000000"}} // Apply dynamic padding
    />
    {!isFocused && searchTerm && (
      <div onClick={handleClickSpan} className="clickable-text">
      <div className="text-container">
        <span style={{ color: darkMode ? '#FFFFFF' : '#000000' }}>
          {currentPrice !== null && currentPrice !== undefined ? `${currentPrice.toFixed(2)} ` : 'N/A'}
        </span>
      </div>
      <div className="search-separator"></div>
      <div className="text-container">
        <span style={{ color: darkMode ? '#FFFFFF' : '#000000' }}>Sentiment </span>
        <span style={{ color: sentimentScoreStyle }}>
          {sentimentScore !== null && sentimentScore !== undefined ? `${sentimentScore.toFixed(2)} ` : 'N/A'}
        </span>
      </div>
      <div className="search-separator"></div>
      <div className="text-container">
        <span style={{ color: darkMode ? '#FFFFFF' : '#000000' }}>Attention Buzz </span>
        <span style={{ color: attenBuzzChangeStyle }}>
          {attentionBuzz !== null && attentionBuzz !== undefined ? attentionBuzz.toFixed(2) : 'N/A'}
        </span>
      </div>
    </div>
    )}
  </form>
    </div>
  );
};

export default SearchBar;