import React, { useState, useEffect, useRef, useCallback, useMemo } from "react";
import { DarkMode, useDisclosure } from '@chakra-ui/react';
import D3PieChart from "./D3PieChart";
import StockPieChart from "./StockPieChart"
import axios from "axios";
import "./StockInfo.css";
import leftArrow from "../left-arrow.svg"; 
import leftArrowInvert from "../left-arrow-invert-down.svg"; 
import leftArrowHover from "../left-arrow-hover.svg";
import {
  Flex,
  Box,
  Text,
  Input,
  Button,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverArrow,
  PopoverCloseButton,
} from '@chakra-ui/react';
import PieSelector from "./PieSelector";


const stockMapping = {
  ALRAJHI: {
    market: "Al Rajhi Bank | TADAWUL",
    sector: "Major Banks",
    currency: "SAR",
  },
  SABIC: {
    market: "Saudi Basic Industries Co. | TADAWUL",
    sector: "Chemicals: Agricultural",
    currency: "SAR",
  },
  EMAAR: {
    market: "Emaar Properties | DFM",
    sector: "Real Estate Development",
    currency: "AED",
  },
  DIB: {
    market: "Dubai Islamic Bank | DFM",
    sector: "Major Banks",
    currency: "AED",
  },
  ARAMCO: {
    market: "Saudi Arabian Oil Co. | TADAWUL",
    sector: "Integrated Oil",
    currency: "SAR",
  },
};
const useCloseOnScroll = (ref, closeFunction) => {
  useEffect(() => {
    const handleScroll = () => {
      // Checks if the element is out of view upon scrolling
      if (ref.current && window.scrollY > ref.current.getBoundingClientRect().bottom) {
        closeFunction();
      }
    };

    // Debounce or throttle this handler if necessary
    window.addEventListener('scroll', handleScroll, true);

    return () => window.removeEventListener('scroll', handleScroll);
  }, [ref, closeFunction]);
};

const getColorClass = (value) => {
  if (value === null || value === undefined) {
    return ""; // Or some default class
  }
  const valueStr = value.toString();
  return valueStr.includes("-") ? "negative" : "positive";
};



const StockInfo = ({ selectedStock, stockName, darkMode, hRestPosition}) => {
  const [stockDetails, setStockDetails] = useState(null);
  const [totalBuzz, setTotalBuzz] = useState(0);
  const [value1, setValue1] = useState(0);
  const [value2, setValue2] = useState(0);
  const [allStockData, setAllStockData] = useState([]);
  const [globalMA, setGlobalMA] = useState('Daily');
  const [newsMA, setNewsMA] = useState('Daily');
  const [socialmediaMA, setSocialmediaMA] = useState('Daily');
  const [attention_buzzDays, setAttentonBuzzDayChange] = useState('Daily');
  const [buzzMA, setBuzzMA] = useState('Daily');
  const [globalMAValue, setGlobalMAValue] = useState(0);
  const [globalMAChange, setGlobalMAChange] = useState(0);
  const [newsMAValue, setNewsMAValue] = useState(0);
  const [newsMAChange, setNewsMAChange] = useState(0);
  const [socialmediaMAValue, setSocialmediaMAValue] = useState(0);
  const [socialmediaMAChange, setSocialmediaMAChange] = useState(0);
  const [buzzMAValue, setBuzzMAValue] = useState(0);
  const [buzzMAChange, setBuzzMAChange] = useState(0);
  const [buzzChangePercentage, setBuzzChangePercentage] = useState(0);
  const [attentionBuzzTotal, setAttentionBuzzTotal] = useState(0);
  const [isPopoverVisible, setIsPopoverVisible] = useState(false);
  const [lastScrollY, setLastScrollY] = useState(window.scrollY);
  const stockInfoRef = useRef(null); // Ref for the scrollable container
  const togglePopover = () => setIsPopoverVisible(!isPopoverVisible);
  const [isHovered, setIsHovered] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  
  useCloseOnScroll(stockInfoRef, onClose);
  const handlePopoverClose = () => {
    if (isOpen) {
      onClose();
    }
  };


  useEffect(() => {
    // Define observerOptions inside the useEffect
    const observerOptions = {
      root: stockInfoRef.current, // Use the container ref as the root
      threshold: 0 // Adjust as needed to detect when the popover is fully within the container
    };
    
    const popoverElement = document.getElementById('popover-element-id'); // You need to set this ID on your PopoverContent
  
    const observer = new IntersectionObserver((entries) => {
      const [entry] = entries;
      if (!entry.isIntersecting) {
        onClose(); // Use onClose from useDisclosure
      } else {
        onOpen(); // Use onOpen from useDisclosure
      }
    }, observerOptions);
  
    if (popoverElement) {
      observer.observe(popoverElement);
    }
  
    return () => {
      if (popoverElement) {
        observer.unobserve(popoverElement);
      }
    };
  }, [onClose, onOpen])



  const handleSocialmediaMAChange = (newValue) => {
    setSocialmediaMA(newValue);
  };
  const handleNoOpChange = (newValue) => {
    // This function intentionally does nothing.
    // It's here to allow changing the dropdown selection without triggering any state updates or actions.
  };

  const calculateMovingAverage = (data, period) => {
    if (data.length === 0) return 0;
  
    const endIndex = data.length - 1;
    const startIndex = Math.max(0, endIndex - period + 1);
    const sum = data.slice(startIndex, endIndex + 1).reduce((acc, item) => acc + item.sentiment, 0);
    const ma = sum / Math.min(period, data.length);
    const change = startIndex > 0 ? ((ma - data[startIndex - 1])/data[startIndex - 1])*100 : 0;
    return {ma: ma, change: change};
  };
  const handleAttentionBuzzDayChange = (newValue) => {
    setAttentonBuzzDayChange(newValue)
  }

  useEffect(() => {
    if (selectedStock) {
      const token = localStorage.getItem("token");

      axios 
        .get(`/api/stocks/${selectedStock.id}`, {
          headers: {
            Authorization: `Token ${token}`,
          },
        })
        .then((response) => {
          setStockDetails(response.data[response.data.length - 1] || {});

        })
        .catch((error) =>
          console.error("Error fetching stock details:", error)
        );
    }
    
  }, [selectedStock]);

  const updateBuzzDetails = useCallback(() => {
    if (!stockDetails) return;
  
    let buzzValue, changeValue;
    switch (attention_buzzDays) {
      case 'Daily':
        buzzValue = stockDetails.attention_buzz;
        changeValue = stockDetails.pctbuzz;
        break;
      case 'Last 7':
        buzzValue = stockDetails.RS7_buzz_score;
        changeValue = stockDetails.RS7_change_buzz_score;
        break;
      case 'Last 30':
        buzzValue = stockDetails.RS30_buzz_score;
        changeValue = stockDetails.RS30_change_buzz_score;
        break;
      case 'Last 90':
        buzzValue = stockDetails.RS90_buzz_score;
        changeValue = stockDetails.RS90_change_buzz_score;
        break;
      default:
        buzzValue = 0;
        changeValue = 0;
        break;
    }
  
    // Assuming setValue1 sets the pie chart value and setTotalBuzz sets the change text
    setValue1(buzzValue);
    setTotalBuzz(buzzValue);
    setBuzzChangePercentage(changeValue);
  }, [attention_buzzDays, stockDetails]);
  
  // Call updateBuzzDetails when either attention_buzzDays or stockDetails change
  useEffect(() => {
    updateBuzzDetails();
  }, [attention_buzzDays, stockDetails, updateBuzzDetails]);


  const handleGlobalMAChange = useCallback((newValue) => {
    setGlobalMA(newValue);
  }, []);
  
  const handleNewsMAChange = useCallback((newValue) => {
    setNewsMA(newValue);
  }, []);
  
  const getMAValue = useCallback((maType) => {
    if (!stockDetails) return { percentage: 0, label: 0 };
  
    let percentage, label;
  
    // Directly access 'Daily' values
    if (maType === 'Daily') {
      percentage = stockDetails.sentiment || 0;
      label = stockDetails.pctsentiment || 0;
    } else {
      // Construct the keys based on the MA type
      const keyPrefix = maType; // '7MA' becomes 'MA7', etc.
      const sentimentKey = `MA${keyPrefix.split("MA")[0]}_sentiment`; // Adjusts '7MA' to 'MA7_sentiment'
      const changeSentimentKey = `MA${keyPrefix.split("MA")[0]}_change_sentiment`; // Adjusts '7MA' to 'MA7_change_sentiment'
  
      percentage = stockDetails[sentimentKey] || 0;
      label = stockDetails[changeSentimentKey] || 0;
    }
  
    return { percentage, label };
  }, [stockDetails, selectedStock]);
  // Use effects to update values based on selections for each MA type
  useEffect(() => {
    const { percentage, label } = getMAValue(globalMA);
    setGlobalMAValue(percentage);
    setGlobalMAChange(label);
  }, [globalMA, getMAValue]);
  
  useEffect(() => {
    const { percentage, label } = getMAValue(newsMA);
    setNewsMAValue(percentage);
    setNewsMAChange(label);
  }, [newsMA, getMAValue]);

  if (!stockDetails) {
    return <div style={{
      color: darkMode ? "white" : "black"
      }}>Loading...</div>; // Or show a spinner/loader
  }

  

  const getStockInfo = (symbol) => {
    return (
      stockMapping[symbol] || { market: "N/A", sector: "N/A", currency: "N/A" }
    );
  };
  const { market, sector, currency } = getStockInfo(selectedStock.symbol);
  const radioGroupStyle = {
    marginTop: "11px" // Apply margin-top of 11px to each radio group
  };
  const formatNumber = (num) => {
    // Check if num is null or undefined
    if (num === null || num === undefined) return null;
  
    // Convert to number if it's not (to handle cases where num might be a string)
    const number = Number(num);
  
    // Check if conversion is successful
    if (isNaN(number)) return null;
  
    // Prepend a '+' sign if the number is positive
    return number > 0 ? `+${number.toFixed(2)}` : number.toFixed(2);
  };
  const getBuzzChangeClass = (value) => {
    return value === 0 ? "buzz-change-zero" : getColorClass(value);
  };
  
  const formatBuzzChangeNumber = (num) => {
    if (num === null || num === undefined) return null;
    const number = Number(num);
    if (isNaN(number)) return null;
    return number === 0 ? "0" : formatNumber(num); // Keep the original formatNumber for general use
  };
  return (
    <div className="stock-info"  ref={stockInfoRef}>
      <div className="stock-title-sticky" style={{
        backgroundColor: darkMode ? '#454c5e' : '#000000', // White when darkMode is true, otherwise black
        color: darkMode ? '#FFFFFF' : '#FFFFFF', // Black text when darkMode is true, otherwise white
        }}>
        <div className = "stock-title-box">
          {stockName}
        </div>
      </div>
      <div className="stock-info-main"  style={{ backgroundColor: darkMode ? '#000000' : 'inherit' }}>
        <div className="stock-info-row">
            <div className="section-main-left">
              <div className="stock-subtitle" style={{ color: darkMode ? '#FFFFFF' : 'inherit' }}>
                {market} <br />
                {sector}
              </div>
              <div className="stock-price-currency">
                <div className="stock-price" style={{ color: darkMode ? '#FFFFFF' : 'inherit' }}>
                  {stockDetails.close.toFixed(2)}
                </div>
                <div className="stock-currency" style={{ color: darkMode ? '#FFFFFF' : 'inherit' }}>{currency}</div>
                <div className="stock-data">
                  <span className={getColorClass(stockDetails.clschange)}>
                    {formatNumber(stockDetails.clschange)} (
                    {formatNumber(stockDetails.pctclschange)}%)
                  </span>
                </div>
              </div>
            
          </div>
          <div className = "seperator-container">
          <div className="vertical-separator" style={{ backgroundColor: darkMode ? '#2A2E39' : '#ccc' }}></div>
          </div>
          <div className="section-right">
          <div className="subtitle-container">
      {/* Group the text and PopoverTrigger together under a Flex container */}
      <Flex alignItems="center" justifyContent="flex-start">
        <Text className="data-subheader" mr="4" style={{ color: darkMode ? '#FFFFFF' : 'inherit' }}>Global Sentiment Score</Text>
        <Popover  isOpen={isPopoverVisible} onClose={() => setIsPopoverVisible(false)} closeOnBlur={true}>
        <PopoverTrigger>
        <Flex
  className="sentiment-button-container"
  alignItems="center"
  justifyContent="flex-start"
  height="30px"
  bg={darkMode ? '#454c5e' : '#000000'}  // Using bg prop for conditional background color
  _hover={{
    bg: darkMode ? '#333333' : 'black',  // Conditional hover background color
    color: 'white'  // Ensuring text color changes on hover
  }}
>
          <Button className="sentiment-button" onClick={togglePopover} bg={darkMode ? "white" : "black"} onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)} color={darkMode ? "black" : "white"} _hover={{ bg:  darkMode ? '#353946' : '#333333', color: 'white' }} cursor="pointer" borderRadius="4px" size="sm"   sx={{
            display: 'inline-flex',
            appearance: 'none',
            alignItems: 'center',
            justifyContent: 'center',
            userSelect: 'none',
            position: 'relative',
            whiteSpace: 'nowrap',
            verticalAlign: 'middle',
            lineHeight: '1.2',
            fontWeight: 'semibold', // Make sure this is defined in your theme or replace it with a numeric value
            height: 'var(--chakra-sizes-8)', // Ensure these variables are defined in your theme
            fontSize: 'var(--chakra-fontSizes-sm)',
            background: 'var(--chakra-colors-black)',
            color: 'var(--chakra-colors-white)',
            cursor: 'pointer',
            paddingLeft: '6px', // Set padding left to 6px
            paddingRight: '4px', // Set padding right to 4px
      
          }}>
            <Flex alignItems="center" justifyContent="space-between" width="100%">
              <Text as="span" overflow="hidden" textOverflow="ellipsis" whiteSpace="nowrap" style={{ color: darkMode ? 'white' : '#FFFFFF' }}>Sentiment Attribution</Text>
              <Box as="img" src= {leftArrow} alt="Back" transform="rotate(270deg)" w="24px" h="17px" marginTop={"2px"} marginLeft = {"5px"}/>
            </Flex>
          </Button>
        </Flex>
      </PopoverTrigger>
      <PopoverContent
          zIndex= "0"
          sx={{
            backgroundColor: darkMode ? "#2a2e39" : "white",
            boxShadow: darkMode ? "0px 0px 4px #2a2e39" : "0px 0px 8px rgba(0, 0, 0, 0.2)",
            borderRadius: "8px",
            border: "0px",
    
          }}
          style={{ backgroundColor: darkMode ? "#2a2e39" : '#FFFFFF' }}
        >
          <PopoverArrow bg={darkMode ? "#2a2e39" : "#FFFFFF"} 
          boxShadow={darkMode ? "-1px -1px 0px 0 #2a2e39" : "-1px -1px 0px 0 #FFFFFF"}/>
          <Flex justifyContent="flex-end" width="95%">
            <PopoverHeader
              sx={{
                borderBottom: 0, // Removes the border-bottom
                fontWeight: 'bold', // Makes the text bold
                fontSize: '15px',
              }}
              style={{ color: darkMode ? '#FFFFFF' : 'inherit' }}
            >
              Weight
            </PopoverHeader>
          </Flex>
          <PopoverBody>
            <Flex direction="column" align="flex-start">

                {/* First Row for News Sentiment */}
                <Flex direction="row" align="center" mb="8px">
                  <Text width="200px" minW="200px" mr="8px" fontSize='14px' style={{ color: darkMode ? '#FFFFFF' : 'inherit' }}>News Sentiment</Text>
                  <Flex align="center" position="relative"
                  sx={{
                    borderRadius: "8px",
                    '&:hover': {
                      backgroundColor: darkMode ? "#353946" : "#F0F3FA",
                    },
                  }}
                  >
                  <Input
                    value={100  }
                    size="sm"
                    fontSize='14px'

                    sx={{
                      borderRadius: "8px",

                    }}
                      _focus={{
                        borderColor: "#936BC3", // Set the focus border color
                        boxShadow: "0 0 0 1px #936BC3", // Set the focus box-shadow
                      }}
                    style={{ color: darkMode ? '#FFFFFF' : 'inherit' }}
                  />
                  <Text fontSize={'14px'} position="absolute" right="8px" zIndex="1" style={{ color: darkMode ? '#FFFFFF' : 'inherit' }}>%</Text>
                </Flex>
                </Flex>
                
                {/* Second Row for Social Media Sentiment */}
                <Flex direction="row" align="center"  mb="8px">
                  <Text width="200px" minW="200px" mr="8px" fontSize='14px' style={{ color: darkMode ? '#FFFFFF' : 'inherit' }}>Social Media Sentiment</Text>
                  <Flex align="center" position="relative" sx={{
                    borderRadius: "8px",
                    '&:hover': {
                      backgroundColor: darkMode ? "#353946" : "#F0F3FA",
                    },
                  }}>
                    <Input value={0}
                    size="sm"
                    fontSize='14px'
                    sx={{
                      borderRadius: "8px",
                      
                    }}
                    _focus={{
                      borderColor: "#936BC3", // Set the focus border color
                      boxShadow: "0 0 0 1px #936BC3", // Set the focus box-shadow
                    }}
                    style={{ color: darkMode ? '#FFFFFF' : 'inherit' }}/>
                    <Text fontSize={'14px'} position="absolute" right="8px" zIndex="1" style={{ color: darkMode ? '#FFFFFF' : 'inherit' }}>%</Text> {/* Adjust position to right */}
                  </Flex>
                </Flex>
                <Flex direction="row" align="center"  mb="8px">
                  <Text width="200px" minW="200px" mr="8px" fontSize='14px' style={{ color: darkMode ? '#FFFFFF' : 'inherit' }}>Sell-Side Sentiment</Text>
                  <Flex align="center" position="relative" sx={{
                    borderRadius: "8px",
                    '&:hover': {
                      backgroundColor: darkMode ? "#353946" : "#F0F3FA",
                    },
                  }}>
                    <Input value={0}
                    size="sm"
                    fontSize='14px'
                    sx={{
                      borderRadius: "8px",
                      
                    }}
                    _focus={{
                      borderColor: "#936BC3", // Set the focus border color
                      boxShadow: "0 0 0 1px #936BC3", // Set the focus box-shadow
                    }}
                    style={{ color: darkMode ? '#FFFFFF' : 'inherit' }}/>
                    <Text fontSize={'14px'} position="absolute" right="8px" zIndex="1" style={{ color: darkMode ? '#FFFFFF' : 'inherit' }}>%</Text> {/* Adjust position to right */}
                  </Flex>
                </Flex>
                <Flex direction="row" align="center">
                  <Text width="200px" minW="200px" mr="8px" fontSize='14px' style={{ color: darkMode ? '#FFFFFF' : 'inherit' }}>Financial Reports Sentiment</Text>
                  <Flex align="center" position="relative" sx={{
                    borderRadius: "8px",
                    '&:hover': {
                      backgroundColor: darkMode ? "#353946" : "#F0F3FA",
                    },
                  }}>
                    <Input value={0}
                    size="sm"
                    fontSize='14px'
                    sx={{
                      borderRadius: "8px",
                      
                    }}
                    _focus={{
                      borderColor: "#936BC3", // Set the focus border color
                      boxShadow: "0 0 0 1px #936BC3", // Set the focus box-shadow
                    }}
                    style={{ color: darkMode ? '#FFFFFF' : 'inherit' }}/>
                    <Text fontSize={'14px'} position="absolute" right="8px" zIndex="1" style={{ color: darkMode ? '#FFFFFF' : 'inherit' }}>%</Text> {/* Adjust position to right */}
                  </Flex>
                </Flex>
              </Flex>
            </PopoverBody>  
        </PopoverContent>
              </Popover>
            </Flex>
              <Flex
                flexDir="row"
                alignItems="center"
              >
                <StockPieChart
                  percentage={formatNumber(globalMAValue * 100)}
                  label={formatNumber(globalMAChange)}
                  className={getColorClass(globalMAChange)}
                  selectedStock = {selectedStock}
                  darkMode = {darkMode}
                />
              </Flex>
              <PieSelector value={globalMA} onChange={handleGlobalMAChange} darkMode = {darkMode} options={[
                { value: "Daily", label: "Daily" },
                { value: "7MA", label: "7MA" },
                { value: "30MA", label: "30MA" },
                { value: "90MA", label: "90MA" }
              ]}/>  
            </div>
          </div>
        </div>
        <div className="stock-info-row">
          
          <div className="section-left">
            <div className="meta-sentiment-title" style={{ color: darkMode ? '#FFFFFF' : 'inherit' }}>Sentiment by Data Source</div>
            {/* Subtitle and PieChart for News Sentiment Score */}
            <div className="subtitle-container">
              <div className="stock-subtitle" style={{ color: darkMode ? '#FFFFFF' : 'inherit' }}>     News Score</div>
              <StockPieChart
                percentage={formatNumber(newsMAValue * 100)}
                label={formatNumber(newsMAChange)}
                className={getColorClass(newsMAChange)}
                selectedStock = {selectedStock}
                darkMode = {darkMode}
              />
              <PieSelector value={newsMA} onChange={handleNewsMAChange} darkMode = {darkMode} options={[
                { value: "Daily", label: "Daily" },
                { value: "7MA", label: "7MA" },
                { value: "30MA", label: "30MA" },
                { value: "90MA", label: "90MA" }
              ]}/>
            </div>
          </div>
          <div className = "seperator-container">
          <div className="vertical-separator" style={{ backgroundColor: darkMode ? '#2A2E39' : '#ccc' }}></div>
          </div>
          {/* Subtitle and PieChart for Research Note Sentiment Score */}
          <div className="section-right">
            <div className="subtitle-container">
              <div className="metadata-subheader" style={{ color: darkMode ? '#FFFFFF' : 'inherit' }}>
                Social Media Score
              </div>
              <StockPieChart
                percentage="Coming Soon" // Assuming PieChart can accept a label or percentage prop for display
                className={getColorClass(newsMAChange)}
                selectedStock = {selectedStock}
                darkMode = {darkMode}
              />
              <PieSelector
                value={"Daily"}
                onChange={handleNoOpChange}
                darkMode = {darkMode}
                options={[
                  { value: "Daily", label: "Daily" },
                  { value: "7MA", label: "7MA" },
                  { value: "30MA", label: "30MA" },
                  { value: "90MA", label: "90MA" }
                ]}
              />
            </div>
          </div>
        </div>
        <div className="stock-info-row">
          <div className="section-left">
            {/* Subtitle and PieChart for News Sentiment Score */}
            <div className="subtitle-container">
              <div className="stock-subtitle" style={{ color: darkMode ? '#FFFFFF' : 'inherit' }}>Sell-Side Research Score</div>
              <StockPieChart
                percentage="Coming Soon" // Assuming PieChart can accept a label or percentage prop for display
                className={getColorClass(newsMAChange)}
                selectedStock = {selectedStock}
                darkMode = {darkMode}
              />
              <PieSelector value={"Daily"} onChange={handleNoOpChange}  darkMode = {darkMode} options={[
                { value: "Daily", label: "Daily" },
                { value: "7MA", label: "7MA" },
                { value: "30MA", label: "30MA" },
                { value: "90MA", label: "90MA" }
              ]}/>
            </div>
          </div>
          <div className = "seperator-container">
          <div className="vertical-separator" style={{ backgroundColor: darkMode ? '#2A2E39' : '#ccc' }}></div>
          </div>
          <div className="section-right">
            <div className="subtitle-container">
              <div className="metadata-subheader" style={{ color: darkMode ? '#FFFFFF' : 'inherit' }}>
                Financial Reports Score
              </div>
              <StockPieChart
                percentage="Coming Soon" // Assuming PieChart can accept a label or percentage prop for display
                className={getColorClass(newsMAChange)}
                selectedStock = {selectedStock}
                darkMode = {darkMode}
              />
              <PieSelector
                value={"Daily"}
                onChange={handleNoOpChange}
                darkMode = {darkMode}
                options={[
                  { value: "Daily", label: "Daily" },
                  { value: "7MA", label: "7MA" },
                  { value: "30MA", label: "30MA" },
                  { value: "90MA", label: "90MA" }
                ]}
              />
            </div>
          </div>
        </div>
        <div className="stock-info-row">
          <div className="section-left">
          <div className="attention-buzz-header" style={{ display: 'flex', alignItems: 'center' }}>
            <div className="attention-buzz-title" style={{ color: darkMode ? '#FFFFFF' : 'inherit' }}>Attention Buzz</div>
            <ul className="buzz-list" style={{ display: 'flex', listStyle: 'none', marginBottom: '5px', paddingLeft: '10px' }}>
              <li className="buzz-item" style={{ marginRight: '10px' }}>
                <span className="color-circle news-buzz-color"></span>
                <span style={{ color: darkMode ? '#FFFFFF' : 'inherit' }}>News Buzz</span>
              </li>
              <li className="buzz-item" style={{ marginRight: '10px' }}>
                <span className="color-circle social-media-buzz-color" style={{ backgroundColor: darkMode ? '#FFFFFF' : '#000000' }}></span>
                <span style={{ color: darkMode ? '#FFFFFF' : 'inherit' }}>Social Media Buzz</span>
              </li>
              <li className="buzz-item" style={{ marginRight: '10px' }}>
                <span className="color-circle social-media-buzz-color" style={{ backgroundColor: darkMode ? '#FFFFFF' : '#000000' }}></span>
                <span style={{ color: darkMode ? '#FFFFFF' : 'inherit' }}>Sell-Side Research Buzz</span>
              </li>
              <li className="buzz-item">
                <span className="color-circle social-media-buzz-color" style={{ backgroundColor: darkMode ? '#FFFFFF' : '#000000' }}></span>
                <span style={{ color: darkMode ? '#FFFFFF' : 'inherit' }}>Financial Reports Buzz</span>
              </li>
            </ul>
          </div>
            <div className="buzz-details">
                <div className="total-buzz" style={{ color: darkMode ? '#FFFFFF' : 'inherit' }}>
                  Total Buzz
                  <br>
                  </br>
                  <span className = "totalbuzz-ml" style={{ color: darkMode ? '#FFFFFF' : 'inherit' }}>
                    {totalBuzz}
                  </span>
                </div>
              <D3PieChart value1={value1} selectedBuzzOption = {attention_buzzDays} darkMode = {darkMode}/>
              <div className="change" style={{ color: darkMode ? '#FFFFFF' : 'inherit' }}>
                Change
                <br />
                <span className={getBuzzChangeClass(buzzChangePercentage)}>
                  {formatBuzzChangeNumber(buzzChangePercentage)}
                </span>
              </div>
            </div>
            <div className="pie-selector-margin-left">
            <PieSelector
                value={attention_buzzDays}
                onChange={handleAttentionBuzzDayChange}
                darkMode={darkMode}
                options={[
                  { value: "Daily", label: "Daily" },
                  { value: "Last 7", label: "Last 7" },
                  { value: "Last 30", label: "Last 30" },
                  { value: "Last 90", label: "Last 90" }
                ]}
                style={{ marginLeft: '15px' }}
              />
              </div>
          </div>
        </div>
        </div>
      </div>
  );
};

export default  React.memo(StockInfo);
