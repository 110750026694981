import { Route, Routes } from "react-router-dom";
import { useMediaQuery } from "@react-hook/media-query";
import Home from "./pages/Home";
import Login from "./pages/Login";
import { ChakraProvider } from "@chakra-ui/react";
import { extendTheme } from '@chakra-ui/react'
import '@fontsource/noto-sans';
import TitleSetter from "./TitleSetter.js";

const theme = extendTheme({
  fonts: {
    loginpage: `'Noto Sans', sans-serif`,
  },
  colors: {
    black: {
      500: '#000',
    },
  },
  components: {
    Radio: {
      baseStyle: {
        control: {
          _checked: {
            bg: 'black !important', // Ensuring background color is black when checked
            borderColor: 'black !important', // Ensuring border color is black when checked
            color: 'white !important', // Ensuring the checkmark/inner circle color is white

            // Targeting the hover state specifically to prevent any background color changes
            _hover: {
              bg: 'black !important', // Keeping the background color black on hover
              borderColor: 'black !important', // Keeping the border color black on hover
            },
          },
          // Additional targeting to prevent blue background on hover for checked state
          _checkedAndHover: {
            bg: 'black !important',
            borderColor: 'black !important',
          },
          // Disabling the data-hover effect
          '[data-hover]': {
            bg: 'black !important',
            borderColor: 'black !important',
          },
        },
      },
    },
  },
});


function App() {
  const isMobile = useMediaQuery("(max-width: 600px)");

  if (isMobile) {
    return (
      <div className="mobile-message">
        <p style={{ margin: "10px" }}>
          Sorry, our app is not supported on mobile devices yet.
        </p>
      </div>
    );
  }

  return (
    <ChakraProvider theme={theme}>
      <Routes>
        <Route path="/" element={<TitleSetter title="NLPify | Enterprise Platform"><Home /></TitleSetter>} />
        <Route path="/login" element={<TitleSetter title="NLPify | Login"><Login /></TitleSetter>} />
      </Routes>
    </ChakraProvider>
  );
}

export default App;
