  import React, { useState, useEffect, useRef } from "react";
  import { useNavigate } from "react-router-dom";
  import axios from "axios";
  import StockList from "../components/StockList";
  import StockData from "../components/StockData";
  import StockInfo from "../components/StockInfo";
  import IndicatorButton from "../components/IndicatorButton";
  import IndicatorPanel from "../components/IndicatorPanel";
  import ResolutionSelector from "../components/ResolutionSelector";
  import CrosshairButton from "../components/CrosshairButton";
  import SearchBar from "../components/SearchBar";
  import logo from "../NLPifyLogo.svg";
  import whitelogo from "../NLPify-whitelogo.svg"
  import NewsFeed from "../components/NewsFeed";
  import ConditionBuilder from "../components/ConditionBuilder";
  import UserSettingsPanel from '../components/UserSettingsPanel';
  import "./Home.css";
  import { IoNewspaperOutline } from "react-icons/io5";
  import { FaRegBookmark } from "react-icons/fa";
  import { IoSettingsOutline } from "react-icons/io5";
  import { IoBookOutline } from "react-icons/io5";
  import { FaRegMoon } from "react-icons/fa";
  import { BiCross } from "react-icons/bi";
  import Highcharts, { error } from 'highcharts';
  import { TbMathFunction } from "react-icons/tb";
  import { seriesType } from "highcharts";
import { AiTwotoneGift } from "react-icons/ai";


  function Home() {
    const [stocks, setStocks] = useState([]);
    const [selectedStock, setSelectedStock] = useState(null);
    const [searchTerm, setSearchTerm] = useState("");
    const [showPanel, setShowPanel] = useState(false);
    const [selectedSentiment, setSelectedSentiment] = useState(null);
    const [sentimentData, setSentimentData] = useState([]);
    const [selectedAttentionBuzz, setSelectedAttentionBuzz] = useState(null);
    const [attentionBuzzData, setAttentionBuzzData] = useState([]);
    const [sentimentPeriod, setSentimentPeriod] = useState("");
    const [inputValue, setInputValue] = useState("");
    const [maType, setMaType] = useState("simple"); // 'simple' as default MA type
    const [showUserSettingsPanel, setShowUserSettingsPanel] = useState(false);
    const [resolvedStock, setResolvedStock] = useState(null)
    const [activePanel, setActivePanel] = useState('stocks');
    const [isIndicatorActive, setIsIndicatorActive] = useState(false);
    const [stockListStyle, setStockListStyle] = useState({});
    const [adjustStockListWidth, setAdjustStockListWidth] = useState(false);
    const [widthAdjustmentNeeded, setWidthAdjustmentNeeded] = useState(false);
    const [width, height] = useWindowSize();
    const [hSeparatorPosition, setHSeparatorPosition] = useState("30%"); // Initial position, adjust as needed
    const [stockDetails, setStockDetails] = useState({});
    const [isCrosshairActive, setIsCrosshairActive] = useState(false);
    const [currentPrice, setCurrentPrice] = useState(null);
    const [sentimentScore, setSentimentScore] = useState(null);
    const [sentimentChange, setSentimentChange] = useState(null);
    const [attentionBuzz, setAttentionBuzz] = useState(null);
    const [bottomHeight, setBottomHeight] = useState("60vh");
    const [activeConditionBuilder, setActiveConditionBuilder] = useState(false);
    const [isCollapsed, setIsCollapsed] = useState(false);
    const [resolution, setResolution] = useState('Daily');
    const [firstMaPeriod, setFirstMaPeriod] = useState(null);
    const [secondMaPeriod, setSecondMaPeriod] = useState(null);
    const [maSE, setMaSE] = useState("");
    const [darkMode, setDarkMode] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [firstMaType, setFirstMaType] = useState("")
    const [secondMaType, setSecondMaType] = useState("")
    const userSettingsPanelRef = useRef(null);
    const settingsButtonRef = useRef(null);
    const [maConfigs, setMaConfigs] = useState({
      first: { period: null, type: null, data: null },
      second: { period: null, type: null, data: null },
    });
    const[searchError, setSearchError] = useState(false);

    const [stockDataContainerWidth, setStockDataContainerWidth] = useState('calc(100%)'); // Initial width based on vSeparatorPosition
    const [separatorMovedCounter, setSeparatorMovedCounter] = useState(0);


    const handleApplySettings = (selectedMaType, period) => {
      // This function assumes "simple" MA type for direct period selection
      setMaConfigs(prevConfigs => {
        // Check if the first MA configuration is already set
        const isFirstMASet = prevConfigs.first && prevConfigs.first.period;
    
        if (!isFirstMASet) {
          // If the first MA is not set, apply settings to the first MA
          return {
            ...prevConfigs,
            first: { period: period, type: selectedMaType} // Assuming "simple" for direct selection
          };
        } else {
          // If the first MA is already set, apply the new settings to the second MA
          return {
            ...prevConfigs,
            second: { period: period, type: selectedMaType } // Use the selected MA type
          };
        }
      });
    };
    
    useEffect(() => {
      setFirstMaPeriod(maConfigs.first.period);
      setSecondMaPeriod(maConfigs.second.period);
      setFirstMaType(maConfigs.first.type)
      setSecondMaType(maConfigs.second.type)
    }, [maConfigs]);
    useEffect(() => {
      document.title = "NLPify | Enterprise Platform";
    }, []);
    const navigate = useNavigate();
 
    // Check for authentication token
    useEffect(() => {
      const token = localStorage.getItem("token");
      if (!token) {
        navigate("/login");
      } else {
        setIsLoading(false); // Set isLoading to false if token exists
        // You can also move your data fetching logic here
      }
    }, [navigate]);
  
    
    useEffect(() => {
      
      const token = localStorage.getItem("token");
      
      axios
        .get(`/api/stocks/`, {
          headers: {
            Authorization: `Token ${token}`,
          },
        })
        .then((response) => {
          setStocks(response.data);

          setSelectedStock(response.data[0]);
          response.data.forEach((stock) => {
            axios.get(`/api/stocks/${stock.id}/`, {
              headers: {
                Authorization: `Token ${token}`,
              },
            })
            .then((res) => {
              // Assuming the last entry is the most recent
              const mostRecentData = res.data[res.data.length - 1] || {};
              setStockDetails(prevDetails => ({
                ...prevDetails,
                [stock.id]: mostRecentData,
              }));
            })
            .catch((error) => console.error(`Error fetching details for ${stock.symbol}:`, error));
          });
        })
        .catch((error) => {
          console.error("Error fetching stocks:", error);
          if (error.response && error.response.status === 401) {
            navigate("/login");
          }
        });
      }, [navigate]);  // Removed [navigate] from dependency array to focus on resize logic
      // Add this useEffect right here
    useEffect(() => {
      const checkAndAdjustWidth = () => {
        const outerContainer = document.querySelector('.stock-list-outer-container');
        if (outerContainer) {
          const widthPercentage = (outerContainer.offsetWidth / window.innerWidth) * 100;
          setAdjustStockListWidth(widthPercentage <= 43);
        }
      };

      checkAndAdjustWidth(); // Call function to adjust width on initial render and on resize
    }, [width, height]);
    useEffect(() => {
      const fetchStocksAndDetails = async () => {
        const token = localStorage.getItem("token");
        try {
          const response = await axios.get(`/api/stocks/`, {
            headers: { Authorization: `Token ${token}` },
          });
          setStocks(response.data);
          if (response.data.length > 0) {
            const firstStock = response.data[0];
            setSelectedStock(firstStock);
            fetchStockDetails(firstStock.id);
          }

        } catch (error) {
          console.error("Error fetching stocks:", error);
          if (error.response && error.response.status === 401) {
            navigate("/login");
          }
        }
      };
      fetchStocksAndDetails();
    }, [navigate]);
    useEffect(() => {
      if (selectedStock && stockDetails[selectedStock.id]) {
        const details = stockDetails[selectedStock.id];
        setCurrentPrice(details.close); // Assuming 'close' is your key for current price
        setSentimentScore(details.sentiment); // Assuming 'sentiment' is your key for sentiment score
        setSentimentChange(details.pctsentiment);
        setAttentionBuzz(details.attention_buzz)
        // Construct the searchTerm to include symbol, current price, sentiment score, and sentiment change
        setSearchTerm(`${selectedStock.symbol}`);
      }
    }, [selectedStock, stockDetails]);
    useEffect(() => {
      setStockListStyle({
        height: `${hSeparatorPosition}`, // Dynamic height based on separator position
        width: adjustStockListWidth ? '100%' : '100%', // Adjust width dynamically
      });
    }, [adjustStockListWidth, hSeparatorPosition]);
    const selectStock = async (stock) => {
      setSelectedStock(stock);
    
      const details = await fetchStockDetails(stock.id);
      setCurrentPrice(details.currentPrice); // Update according to your data structure
      setSentimentScore(details.sentimentScore); // Update according to your data structure
      setSentimentChange(details.sentimentChange); // Update according to your data structure
      setAttentionBuzz(details.attentionBuzz)
    };
    useEffect(() => {
      if (selectedStock) {
        fetchStockDetails(selectedStock.id);
      }
    }, [selectedStock]);

    const togglePanel = () => {
      setShowPanel(!showPanel);
      setIsIndicatorActive(!isIndicatorActive);
    };
    const updateInputValue = (value) => {
      setInputValue(value);
    };
    
    const getSentimentDetails = () => {
      if (!selectedStock || !stockDetails[selectedStock.id]) {
          return { score: null, change: null };
      }

      const { sentiment, pctsentiment } = stockDetails[selectedStock.id];
      return {
          score: sentiment,
          change: pctsentiment,
      };
    }
    const getFormattedSentimentDetails = () => {
      if (!selectedStock || !stockDetails[selectedStock.id]) return "";

      const score = stockDetails[selectedStock.id].sentiment;
      const change = stockDetails[selectedStock.id].pctsentiment;

      // Formatting sentiment score and change
      const formattedScore = score ? `${score >= 0 ? "+" : ""}${score.toFixed(2)}` : "N/A";
      const formattedChange = change ? `${change >= 0 ? "+" : ""}${change.toFixed(2)}%` : "N/A";

      return `${selectedStock.symbol} ${formattedScore} ${formattedChange}`;
  };
    const handleSettingsClick = () => {
      setShowUserSettingsPanel(!showUserSettingsPanel); // Toggle the settings panel
    };

    const handleToggleDarkMode = () =>{
    setDarkMode(!darkMode);
    }
    // Function to handle document click
  const handleDocumentClick = (event) => {
    if (userSettingsPanelRef.current && !userSettingsPanelRef.current.contains(event.target) &&
        !settingsButtonRef.current.contains(event.target)) { // Check if click is not on the button itself
      setShowUserSettingsPanel(false);
    }
  };
  const calculateAdjustedWidth = () => {
    const screenWidth = window.innerWidth;
    const minWidth = 1440; // Assumed minimum screen width
    const minPercentage = 0.6;
    const maxPercentage = 1.2;
  
    // For screens at or smaller than the minimum width, use the max percentage
    if (screenWidth <= minWidth) {
      return maxPercentage;
    } else {
      // Adjust maxRange to a smaller value to make the scale reach minPercentage quicker
      const maxRange = 1920 - minWidth; // Adjusted max width for quicker scaling
      const range = screenWidth - minWidth; // Distance beyond minimum width
      const scale = range / maxRange; // Adjusted proportion of the way between minWidth and maxRange
      const interpolatedPercentage = maxPercentage - ((maxPercentage - minPercentage) * scale);
      return Math.max(minPercentage, Math.min(maxPercentage, interpolatedPercentage));
    }
  };
  useEffect(() => {
    let handler;
    // Delay the addition of the event listener
    if (showUserSettingsPanel) {
      handler = setTimeout(() => {
        document.addEventListener('click', handleDocumentClick);
      }, 10); // Delay just enough for the click event to pass
    }

    // Cleanup the event listener and timeout
    return () => {
      clearTimeout(handler);
      document.removeEventListener('click', handleDocumentClick);
    };
  }, [showUserSettingsPanel]);
    useEffect(() => {

    }, [darkMode]); 
    const handleSelectStock = (stock) => {
      setSelectedStock(stock);
      setSearchTerm(stock.symbol)
      setInputValue(stock.symbol)
    };
    const handleSearch = (searchTerm) => {
      // Ensure stocks is an array before calling find
      if (Array.isArray(stocks)) {
        const stock = stocks.find(
          (s) => s.symbol.toLowerCase() === searchTerm.toLowerCase()
        );
        if (stock) {
          handleSelectStock(stock); // Valid stock found
          setSearchTerm(stock.symbol); // Update searchTerm to the found stock symbol
          setSearchError(false); // Clear any previous error state
        } else {
          setSearchError(true);
        }
      }


    };

    const [hRestPosition, setHRestPosition] = useState("58.7vh");

    const handleInputChange = (value) => {
      setInputValue(value); // Update the input value as the user types
    };
    const fetchStockDetails = async (stockId) => {
      const token = localStorage.getItem("token");
      try {
        const detailsResponse = await axios.get(`/api/stocks/${stockId}/`, {
          headers: { Authorization: `Token ${token}` },
        });
        const mostRecentData = detailsResponse.data[detailsResponse.data.length - 1] || {};
        setStockDetails(prevDetails => ({ ...prevDetails, [stockId]: mostRecentData }));
        // Update search term or other UI elements as needed
      } catch (error) {
        console.error(`Error fetching details for stock ID ${stockId}:`, error);
      }
    };
    const sentimentScoreStyle = sentimentScore > 0 ? 'green' : sentimentScore < 0 ? 'red' : (darkMode && sentimentScore === 0) ? 'white' : 'black';
    const sentimentChangeStyle = getSentimentDetails().change > 0 ? 'green' : getSentimentDetails().change < 0 ? 'red' : (darkMode && getSentimentDetails().change === 0) ? 'white' : 'black';
    const attenBuzzChangeStyle = attentionBuzz > 0 ? 'green' : attentionBuzz < 0 ? 'red' : (darkMode && attentionBuzz === 0) ? 'white' : 'black';
    
    const collapseThreshold = 27.6562;
    const handleHSeparatorDrag = (e) => {
      let calculatedPosition = (e.clientY / window.innerHeight) * 100 - 14; // Calculate position as a percentage of the window height
    
      if (calculatedPosition < 0) return;
    
      // Maximum limit, adjust as needed
      const maxLimitPercentage = 90; // Example: Limit dragging to 90% of the window height
    
      calculatedPosition = Math.max(calculatedPosition, 0); // Minimum limit, adjust as needed
      calculatedPosition = Math.min(calculatedPosition, 86); // Apply maximum limit
    
      setHSeparatorPosition(`${calculatedPosition}%`);
      setHRestPosition(`${100 - calculatedPosition - 14 + 2.7}vh`); // Adjust calculation as needed based on your UI
    };
    const [vSeparatorPosition, setVSeparatorPosition] = useState("48%"); // Initial position, adjust as needed
    const minMainContentWidth = 300;
    const windowWidth = window.innerWidth;
    const maxSidebarWidth = windowWidth - minMainContentWidth;

    // Calculate the collapse threshold as a percentage of the window width
    const thresholdPercentage = (maxSidebarWidth / windowWidth) * 100;

    const handleVSeparatorDrag = (e) => {
      setSeparatorMovedCounter(prevCounter => {
        const resetThreshold = 1000000; // Example threshold
        return prevCounter >= resetThreshold ? 0 : prevCounter + 1;
      });
  
      let newPosition = (e.clientX / window.innerWidth) * 100;
      newPosition = Math.max(newPosition, 2.4); // Ensure minimum position
  
      const chartContainers = document.querySelectorAll('[id^="chart"]');
      chartContainers.forEach(container => {
        container.style.width = stockDataContainerWidth;
      });
  
      // Directly check for un-collapse scenario
      if (newPosition < 100 - collapseThreshold) {
        if (isCollapsed) {
          setIsCollapsed(false);
          setVSeparatorPosition(`${100 - newPosition}%`);
        }
      }
  
      // Handle the logic for collapsing the panel based on newPosition
      if (!isCollapsed && newPosition >= 100 - collapseThreshold ) {
        if (newPosition >= 100 - collapseThreshold + 15) {
          setIsCollapsed(true);
          const adjustedWidthPercentage = calculateAdjustedWidth();
          setVSeparatorPosition(`${adjustedWidthPercentage}vw`);
        } 
      } else if (!isCollapsed) {
        setVSeparatorPosition(`${100 - newPosition}%`);
      }
  
      // No change in collapsing logic here to ensure it still collapses when expected
      if (isCollapsed && newPosition >= 100 - collapseThreshold + 15) {
        const adjustedWidthPercentage = calculateAdjustedWidth();
        setVSeparatorPosition(`${adjustedWidthPercentage}vw`);
      }
  
      setStockDataContainerWidth(`calc(100% - ${vSeparatorPosition})`);
  
      Highcharts.charts.forEach(chart => chart?.reflow());
  };

    const handleSentimentSelected = (sentimentType) => {

      setSelectedSentiment(sentimentType);
      // No need for an additional fetch if sentiment data is included in stockData
    };
    const toggleNewsPanel = () => {
      setActivePanel(activePanel === 'stocks' ? 'news' : 'stocks');
    };
    const toggleCrosshair = () => {
      setIsCrosshairActive(!isCrosshairActive);
    };
    const showStockListPanel = () => {
      setActivePanel('stocks');
    };  


    // Function to switch to the NewsFeed panel
    const showNewsFeedPanel = () => {
      setActivePanel('news');
    };
    const handleAttentionBuzzSelected = (buzzType) => {

      setSelectedAttentionBuzz(buzzType);
      // Fetch and set data for attention buzz chart
      const updatedData = stocks.map((stock) => {
        return {
          ...stock,
          attention_buzz: stock.attention_buzz || 0, // Ensure null values are replaced with 0
        };
      });
      setAttentionBuzzData(updatedData); // This is a simplistic approach; adapt based on actual data structure and requirements
    };
    const handleDeleteSentiment = () => {
      setSelectedSentiment(null); // Update the state to reflect the deletion
      // Any additional cleanup or state updates can go here
    };
    const handleDeleteAttentionBuzz = () => {
      setSelectedAttentionBuzz(null); // Update the state to reflect the deletion
      // Any additional cleanup or state updates can go here
    };

    useEffect(() => {
      const resolveStockBasedOnResolution = () => {
        if (stocks.length > 0 && selectedStock) { // Ensure stocks are loaded
          if (resolution === 'Weekly') {
            const weeklySymbol = `weekly_${selectedStock.symbol}`;
  
    
            const weeklyStock = stocks.find(stock => stock.symbol === weeklySymbol);
  
    
            if (weeklyStock) {
              setResolvedStock(weeklyStock);
            }
          } else if (resolution === 'Monthly') {
            const monthlySymbol = `monthly_${selectedStock.symbol}`;

    
            const monthlyStock = stocks.find(stock => stock.symbol === monthlySymbol);
     
    
            if (monthlyStock) {
              setResolvedStock(monthlyStock);
            }
         } else {
            setResolvedStock(selectedStock);
          }
        }
      };
    
      resolveStockBasedOnResolution();
    }, [selectedStock, resolution, stocks]);
    if (isLoading) {
      return <div></div>; // Or any other loading indicator you prefer
    }
    return (  
      <div className="Home">
        <div className="settings-logo-container" style={{ backgroundColor: darkMode ? '#000000' : '#FFFFFF' }}>
        <div className="logo-container" style={{ marginLeft: '45%' }}>
          <img src={darkMode ? whitelogo : logo} alt="NLPify Logo" />
        </div>
          <div className = "settings-button-container"style={{ color: darkMode ? '#FFFFFF' : '#000000', backgroundColor: darkMode ? '#000000' : '#FFFFFF' }}> 
          <button ref={settingsButtonRef} className="settings-button" style={{backgroundColor: darkMode ? '#000000' : '#FFFFFF' }} title="Settings" onClick={() => setShowUserSettingsPanel(prev => !prev)}>
            <IoSettingsOutline size={25} />
            </button>
            {showUserSettingsPanel && (
            <div ref={userSettingsPanelRef} >
              <UserSettingsPanel darkMode={darkMode} onToggleDarkMode={handleToggleDarkMode} />
            </div>
          )}

          </div>
        </div>
        <div className="indicator-and-search-container"  style={{ backgroundColor: darkMode ? '#000000' : '#FFFFFF', border: darkMode ? '2px solid #2A2E39' : '2px solid #D3D3D3' }}>
          <div className="search-container">
          <SearchBar
            searchTerm={searchTerm}
            searchError={searchError}
            currentPrice={stockDetails[selectedStock?.id]?.close} // Fetch the "close" value for the current stock
            sentimentScore={getSentimentDetails().score}
            sentimentChange={getSentimentDetails().change}
            attentionBuzz={attentionBuzz}
            onSearch={handleSearch}
            sentimentScoreStyle={sentimentScoreStyle}
            sentimentChangeStyle={sentimentChangeStyle}
            attenBuzzChangeStyle = {attenBuzzChangeStyle}
            darkMode={darkMode}
          />
          </div>
          
          <div className="indicator-button-container">
            <IndicatorButton isActive={isIndicatorActive}onClick={togglePanel}  darkMode={darkMode}>
              <IoBookOutline size={25} />
            </IndicatorButton>
          </div>
          <div className = "crosshair-button-container">
            <CrosshairButton
                isActive={isCrosshairActive}
                onClick={toggleCrosshair}
                darkMode={darkMode}

            > 
              <BiCross size={25} />
            </CrosshairButton>
          </div>
          <div className="resolution-button-container">
            <ResolutionSelector onSelectionChange={(option) => setResolution(option)} darkMode={darkMode} />
        </div>
        </div>
        <IndicatorPanel
          togglePanel={togglePanel}
          showPanel={showPanel}
          onSentimentSelected={handleSentimentSelected}
          onAttentionBuzzSelected={handleAttentionBuzzSelected}
          onSentimentPeriodSelected={setSentimentPeriod}
          maType = {maType}
          setMaConfigs={setMaConfigs}
          setMaType= {maType}
          onApplySettings={handleApplySettings}
          darkMode  = {darkMode}
        />
        <div className="content-container" style={{ backgroundColor: darkMode ? '#000000' : 'inherit' }}>
          <div
            className="stock-data-container"
            style={{ width: `calc((100% - ${vSeparatorPosition}) - 50px)` }} // Use the inverse of vSeparatorPosition for the width
          >
              {selectedStock && (
              <StockData
                stock={resolvedStock}
                symbol = {selectedStock.symbol}
                maConfigs = {maConfigs}
                selectedSentiment={selectedSentiment}
                selectedAttentionBuzz={selectedAttentionBuzz}
                sentimentPeriod={sentimentPeriod}
                stockDataContainerWidth={stockDataContainerWidth}
                maType={maType}
                setMaConfigs={setMaConfigs}
                firstMaPeriod={firstMaPeriod}
                secondMaPeriod={secondMaPeriod}
                firstMaType={firstMaType}
                secondMaType={secondMaType}
                searchTerm={searchTerm}
                handleDeleteSentiment={handleDeleteSentiment}
                isCrosshairActive= {isCrosshairActive}
                separatorMovedCounter={separatorMovedCounter}
                handleDeleteAttentionBuzz = {handleDeleteAttentionBuzz}
                resolution= {resolution }
                darkMode = {darkMode}
              />
              )} 
          </div>
          <div
              className="vertical-divider"
              onMouseDown={() => {
                document.addEventListener("mousemove", handleVSeparatorDrag);
                document.addEventListener("mouseup", () => {
                  document.removeEventListener("mousemove", handleVSeparatorDrag);
                });
              }}
            />
          <div
            className="stock-list-outer-container"
            style={{ width: `${vSeparatorPosition}`, backgroundColor: darkMode ? '#000000' : '#FFFFFF', borderLeft: darkMode ? "5px solid #2A2E39" : " 5px solid#D3D3D3" }}
          >
            <div
              className="vertical-divider"
              onMouseDown={() => {
                document.addEventListener("mousemove", handleVSeparatorDrag);
                document.addEventListener("mouseup", () => {
                  document.removeEventListener("mousemove", handleVSeparatorDrag);
                });
              }}
            />
            <div
              className="stock-list-container"
              style={stockListStyle}
            >
                {activePanel === 'stocks' && (
                  
                  <StockList
                    stocks={stocks}
                    onSelect={setSelectedStock}
                    updateInputValue={updateInputValue}
                    selectedStock={selectedStock}
                    darkMode = {darkMode}
                  />
                )}
                 {activePanel === 'news' && (
                  
                  <NewsFeed symbol={selectedStock.symbol} darkMode= {darkMode}/>
                )}
                {activePanel === 'conditionBuilder' && (
                  
                  <ConditionBuilder
                    darkMode={darkMode}
                    // props you might need to pass to the ConditionBuilder
                  />
                )}
            </div>
            <div
                className="separator" style={{backgroundColor: darkMode ? '#2A2E39' : '#D3D3D3'}}
                onMouseDown={() => {
                  document.addEventListener("mousemove", handleHSeparatorDrag);
                  document.addEventListener("mouseup", () => {
                    document.removeEventListener(
                      "mousemove",
                      handleHSeparatorDrag
                    );
                  });
                }}
              />
            <div
              className="info-wrapper"
              style={{ top: `${hSeparatorPosition}`, backgroundColor: darkMode ? 'black' : 'white'}}
            >
              
              <div
                className="stock-info-container"
                style={{ height: `${hRestPosition}` }}
              >

                {selectedStock && (
                  <StockInfo
                    selectedStock={selectedStock}
                    stockName={selectedStock.symbol}
                    darkMode = {darkMode}
                    hRestPosition = {hRestPosition}
                  />
                )}
              </div>
            </div>
          </div>
          <div className={`right-sidebar ${darkMode ? 'dark-mode' : ''}`}>  
            <button
              className={activePanel === 'stocks' ? 'sidebar-button active' : 'sidebar-button'}
              title="Watchlist"
              onClick={() => setActivePanel('stocks')}
            >
              <FaRegBookmark size={25} />
            </button>
            <button
              className={activePanel === 'news' ? 'sidebar-button active' : 'sidebar-button'}
              title="News"
              onClick={() => setActivePanel('news')}
            >
              <IoNewspaperOutline size={25} />
            </button>
            <button
              className={activePanel === 'conditionBuilder' ? 'sidebar-button active' : 'sidebar-button'}
              title="Condition Builder"
              onClick={() => setActivePanel(activePanel !== 'conditionBuilder' ? 'conditionBuilder' : 'stocks')}
            >
              <TbMathFunction size={25} />
            </button>
          </div>
        </div>
      </div>
    );
  }
  function useWindowSize() {
    const [size, setSize] = useState([window.innerWidth, window.innerHeight]);

    useEffect(() => {
      const handleResize = () => setSize([window.innerWidth, window.innerHeight]);
      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    }, []);

    return size;
  }

  export default Home;
