import { useEffect } from "react";

const TitleSetter = ({ title, children }) => {
  useEffect(() => {
    document.title = title;
  }, [title]);

  return children || null;
};

export default TitleSetter;