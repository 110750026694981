import React, { useState, useRef, useEffect } from "react";
import "./IndicatorPanel.css";
import leftArrow from "../left-arrow.svg"; // Ensure this path is correct
import leftArrowInvert from "../left-arrow-invert.svg"; // Ensure this path is correct
import IndicatorSettingsPanel from "./IndicatorSettingsPanel";
import { BsThreeDots } from "react-icons/bs";

const IndicatorPanel = ({
  togglePanel,
  showPanel,
  onSentimentSelected,
  onAttentionBuzzSelected,
  onSentimentPeriodSelected,
  maType,
  setMaType,
  onApplySettings,
  darkMode
}) => {
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [overlayVisible, setOverlayVisible] = useState(false);
  const [overlayPosition, setOverlayPosition] = useState({ x: 0, y: 0 });
  const [overlayOption, setOverlayOption] = useState("");
  const overlayRef = useRef(null);
  const [showIndicatorSettings, setShowIndicatorSettings] = useState(false);
  useEffect(() => {
    const adjustPanelPosition = () => {
      const headerElement = document.querySelector(".indicator-and-search-container"); // This is the "aligned element class"
      if (headerElement) {
        const headerBottom = headerElement.getBoundingClientRect().bottom - 1;
        const indicatorPanel = document.querySelector('.indicator-panel');
        indicatorPanel.style.top = `${headerBottom}px`;
      }
    };
  
    window.addEventListener('resize', adjustPanelPosition);
    adjustPanelPosition();
  
    return () => {
      window.removeEventListener('resize', adjustPanelPosition);
    };
  }, []);
  // Handler when edit settings is clicked
  const handleEditSettingsClick = () => {
    setShowIndicatorSettings(true);
    setOverlayVisible(false);
  };

  // When an MA type change is selected from the settings panel
  const handleMaTypeChange = (newMaType) => {
    setMaType(newMaType); // Propagate up to change the MA type
  };
  useEffect(() => {
    let timeoutId;
  
    if (!showPanel) {
      // Set a timeout to delay the state reset
      timeoutId = setTimeout(() => {
        setShowIndicatorSettings(false);
        setSelectedCategory(null);
      }, 200); // Delay in milliseconds
    }
  
    return () => {
      // Clear the timeout if the component unmounts or if showPanel changes again
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [showPanel]);
  // When an option is clicked in the panel
  const handleOptionClick = (option) => {
    if (option.endsWith("Sentiment")) {
      onSentimentSelected(option);
      onSentimentPeriodSelected(""); // Reset the period if only sentiment is selected
    } else if (option.endsWith("Buzz")) {
      onAttentionBuzzSelected(option);
    }
    setOverlayVisible(false);
  };

  // When a period is selected
  const handlePeriodSelect = (period) => {
    // Directly call onApplySettings with the selected period and the current MA type
    onApplySettings(maType, period);
    setOverlayVisible(false);
  };

  // Handle the more options button (three dots)
  const handleDotsClick = (event, option) => {
    event.stopPropagation();
    const rect = event.currentTarget.getBoundingClientRect();
    setOverlayPosition({ x: rect.left, y: rect.top - 72 });
    setOverlayVisible(true);
    setOverlayOption(option);
  };

  // Close the overlay
  const closeOverlay = () => {
    setOverlayVisible(false);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (overlayRef.current && !overlayRef.current.contains(event.target)) {
        setOverlayVisible(false);
      }
    };

    // Adjust panel width dynamically
    const adjustPanelWidth = () => {
      const indicatorPanel = document.querySelector('.indicator-panel');
      if (indicatorPanel) {
        indicatorPanel.style.width = 'auto';
        requestAnimationFrame(() => {
          const currentWidth = indicatorPanel.offsetWidth;
          const adjustedWidth = currentWidth * 1.2;
          indicatorPanel.style.width = `${adjustedWidth}px`;
        });
      }
    };

    adjustPanelWidth();
    window.addEventListener('resize', adjustPanelWidth);
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      window.removeEventListener('resize', adjustPanelWidth);
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showPanel, selectedCategory, overlayVisible]);

  const sentimentOptions = [
    "Global Sentiment",
    "News Sentiment",
  ];
  const attentionBuzzOptions = [
    "Total Buzz",
    "News Buzz",
  ];
  
  // Render options for the indicator panel
  const renderOptions = (options, includeDots) => {
    return options.map((option, index) => (
      <div key={index} className="panel-option"  style={{ color: darkMode ? '#FFFFFF' : '#000000'}} onClick={() => handleOptionClick(option)}>
        <span>{option}</span>
        {includeDots && (
          <BsThreeDots
            className="dots-button"
            onClick={(e) => handleDotsClick(e, option)}
          />
        )}
      </div>
    ));
  };

  return (
    <div className={`indicator-panel ${showPanel ? "open" : ""} ${darkMode ? 'dark-mode' : ''}`}>
      {selectedCategory && (
        <div className="panel-header" style={{
          backgroundColor: darkMode ? '#454c5e' : '#000000', // White when darkMode is true, otherwise black
          }}>
          <span className="studies-title" style={{
              backgroundColor: darkMode ? '#454c5e' : '#000000', // White when darkMode is true, otherwise black
              color: darkMode ? '#FFFFFF' : '#FFFFFF', // Black text when darkMode is true, otherwise white
              }}>{selectedCategory}</span>
          <button className="back-button" onClick={() => setSelectedCategory(null)}>
            <img src={leftArrow} alt="Back" />
          </button>
        </div>
      )}
      <div className={`panel-container ${darkMode ? 'dark-mode' : ''}`} style={{
        backgroundColor: darkMode ? '#000000' :'#FFFFFF',
        boxShadow: darkMode ? "0px 0px 4px #2a2e39" : "0px 0px 8px rgba(0, 0, 0, 0.1)",
        }}>
        {!selectedCategory && (
          // Render the panel header inside the panel container when no category is selected
          <div className="panel-header">
            <div className="studies-title" style={{
              backgroundColor: darkMode ? '#454c5e' : '#000000', // White when darkMode is true, otherwise black
              color: darkMode ? '#FFFFFF' : '#FFFFFF', // Black text when darkMode is true, otherwise white
              }}>NLP Indicators</div>
          </div>
        )}
        {!selectedCategory ? (
          <>
            <div className="panel-option" style={{ color: darkMode ? '#FFFFFF' : '#000000'}} onClick={() => setSelectedCategory("Sentiment")}>
              Sentiment
            </div>
            <div className="panel-option" style={{ color: darkMode ? '#FFFFFF' : '#000000' }} onClick={() => setSelectedCategory("Attention Buzz")}>
              Attention Buzz
            </div>
          </>
        ) : (
          <>
            {selectedCategory === "Sentiment" && renderOptions(sentimentOptions, true)}
            {selectedCategory === "Attention Buzz" && renderOptions(attentionBuzzOptions, false)}
          </>
        )}
      </div>
      {overlayVisible && (
        <div
          ref={overlayRef}
          className="indicator-options-overlay"
          style={{
            top: `${overlayPosition.y}px`,
            left: `${overlayPosition.x}px`,
            backgroundColor: darkMode ? '#2a2e39' : '#FFFFFF', // White when darkMode is true, otherwise black
            color: darkMode ? '#FFFFFF' : '#000000', // Black text when darkMode is true, otherwise white
            boxShadow: darkMode ? "0px 0px 4px #2a2e39" : "0px 0px 8px rgba(0, 0, 0, 0.1)",

          }}
        >
          {/* Additional settings based on overlayOption */}
          <div className="overlay-option" onClick={() => handlePeriodSelect(30)}>
            Apply 30 Period MA
          </div>
          <div className="overlay-option" onClick={() => handlePeriodSelect(90)}>
            Apply 90 Period MA
          </div>
          <div className="overlay-option" onClick={handleEditSettingsClick}>
            Edit Moving Average
          </div>
        </div>
      )}
      {showIndicatorSettings && (
        <IndicatorSettingsPanel darkMode = {darkMode} onClose={() => setShowIndicatorSettings(false)} onApply={(maType, period) => onApplySettings(maType, period)} />
      )}
    </div>
  );
};

export default IndicatorPanel;
