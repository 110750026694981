import { Flex } from "@chakra-ui/react";
import LoginInput from "../components/LoginInput.js";
import LoginBranding from "../components/LoginBranding.js";
import React, { useState, useEffect } from "react";
const Login = () => {
    useEffect(() => {
        document.title = "NLPify | Login";
      }, []);
    return (
        <Flex
            flexDirection="row"
            width="100wh"
            height="100vh"
            alignItems="center"            
        >
            <LoginBranding/>
            <LoginInput/>
        </Flex>
    )
}

export default Login;
