import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './NewsFeed.css';

const NewsFeed = ({ symbol, darkMode}) => {
  const [newsData, setNewsData] = useState([]);
  const hoverBackgroundColor = darkMode ? '#333333' : '#F0F3FA'; 
  useEffect(() => {
    if (!symbol) return;

    const fetchNews = async () => {
      try {
        const response = await axios.get(`/api/news/${symbol}`);
        setNewsData(response.data); // Save fetched data in state
 
      } catch (error) {
        console.error('Failed to fetch news data:', error);
      }
    };

    fetchNews();
  }, [symbol]);

  return (
    <div className="news-container" style={{ backgroundColor: darkMode ? '#000000' : '#FFFFFF' }}>
    <div className = "news-header-container">
    <h1 style={{ marginLeft: '5px' }}>NEWS FEED</h1>
    </div>
    <div className="news-items-container"  style={{ backgroundColor: darkMode ? '#000000' : '#FFFFFF', borderTop: darkMode ? "1px solid #2a2e39" : "1px solid 808080" }}>
      {newsData.map((newsItem, index) => (
        <div key={index} className="news-item" style = {{borderBottom: darkMode ? "1px solid #2a2e39":"1px solid #d3d3d3"}}onMouseEnter={(e) => e.currentTarget.style.backgroundColor = hoverBackgroundColor}
        onMouseLeave={(e) => e.currentTarget.style.backgroundColor = ''}>
          <div className="news-header" style={{ color: darkMode ? '#000000' : '#FFFFFF' }}>
            <span className="news-date">{newsItem.published_date}</span>
            <span className="news-publisher">{newsItem.source}</span>
          </div>
          <div className="news-title" style={{ color: darkMode ? '#FFFFFF' : '#000000' }}>
            <a href={newsItem.link} target="_blank" rel="noopener noreferrer">
              {newsItem.title}
            </a>
          </div>
        </div>
      ))}
    </div>
  </div>
  );
};

export default NewsFeed;