  import React, { useState, useEffect } from "react";
  import axios from "axios";
  import "./StockList.css"; // Ensure this file exists and contains the necessary styles
  import { useNavigate } from "react-router-dom";

  function StockList({ onSelect, selectedStock, updateInputValue, darkMode}) {
    const [stocks, setStocks] = useState([]);
    const [stockDetails, setStockDetails] = useState({});
    const [selectedStockId, setSelectedStockId] = useState(null);
    const navigate = useNavigate();
    const hoverStyle = {
      backgroundColor: darkMode ? '#333333' : '#F0F3FA', // Dark grey when darkMode is true, light grey otherwise
      cursor: 'pointer'
    };
    useEffect(() => {
      const token = localStorage.getItem("token");

      axios
        .get(`/api/stocks/`, {
          headers: {
            Authorization: `Token ${token}`,
          },
        })
        .then((response) => {
          setStocks(response.data);
          // Fetch details for each stock
          response.data.forEach((stock) => {
            axios
              .get(`/api/stocks/${stock.id}/`, {
                headers: {
                  Authorization: `Token ${token}`,
                },
              })
              .then((res) => {
                // Assuming the last entry in the array is the most recent
                const mostRecentData = res.data[res.data.length - 1] || {};
                setStockDetails((prevDetails) => ({
                  ...prevDetails,
                  [stock.id]: mostRecentData,
                }));
              })
              .catch((error) =>
                console.error(
                  `Error fetching stock details for ${stock.symbol}:`,
                  error
                )
              );
          });
        })
        .catch((error) => {
          console.error("Error fetching stocks:", error);
          if (error.response.status === 401) {
            navigate("/login");
          }
        });
    }, [navigate]);

    const handleSelect = (stock) => {
      setSelectedStockId(stock.id);
      onSelect(stock);
      updateInputValue(stock.symbol)
    };

    const displayValue = (stockId, key) => {
      let value = stockDetails[stockId] ? stockDetails[stockId][key] : null;
      const isValueChangeColumn = ["clschange", "pctclschange", "pctsentiment", "pctbuzz"].includes(key);
      let color = "#000000"; // Default to black for all values
    
      // Apply color logic for sentiment and attention_buzz separately
      if (key === "sentiment" || key === "attention_buzz") {
        color = value > 0 ? "#00A36C" : value < 0 ? "#FF2400" : (darkMode ? "#FFFFFF" : "#000000"); // green for > 0, red for < 0, white or black for 0 in dark mode
      } else if (isValueChangeColumn && key !== "close") {
        color = value > 0 ? "#00A36C" : value < 0 ? "#FF2400" : (darkMode ? "#FFFFFF" : "#000000"); // green for > 0, red for < 0, white or black for 0 in dark mode
      } else if (darkMode) {
        color = "#FFFFFF";  // Set color to white if the value is 0 and dark mode is true
      }
    
      let formattedValue = typeof value === "number" ? value.toFixed(2) : value;
      let percentageSign = "";
    
      if (["pctclschange", "pctsentiment"].includes(key) && typeof value === "number") {
        formattedValue = (value).toFixed(2);
        percentageSign = "%"; // Add percentage sign for these keys
      } else if (key === "pctbuzz" && typeof value === "number") {
        formattedValue = value.toFixed(0); // Integer value for pctbuzz
      }
    
      // Wrap both value and percentage sign in the same span for consistent coloring
      return (
        <span style={{ color }}>
          {formattedValue}{percentageSign}
        </span>
      );
    };

    return (
      <div style={{ display: 'flex', flexDirection: 'column', height: '100%', zIndex: '20' }}>
        <div className="stock-list-headers" style={{ backgroundColor: darkMode ? '#000000' : '#FFFFFF' }}>
          <div className="main-header" style={{ 
          backgroundColor: darkMode ? '#000000' : '#FFFFFF', 
          color: darkMode ? '#FFFFFF' : '#000000' }}>Testing Watchlist</div>
            <div className={`watchlist-header ${darkMode ? 'dark-mode' : ''}`} style={{ backgroundColor: darkMode ? '#000000' : '#FFFFFF' }}>
              <div className="header-item">Company</div>
              <div className="header-item">Last</div>
              <div className="header-item">Chg</div>
              <div className="header-item header-item-with-line dark-mode">Chg %</div>
              <div className="header-item">Sentiment Score</div>
              <div className="header-item header-item-with-line dark-mode">Chg%</div>
              <div className="header-item">Attention Buzz</div>
              <div className="header-item">Chg</div>
            </div>
            <div className="stock-list-separator" style = {{backgroundColor: darkMode ? '#2A2E39': '#D3D3D3'}}></div>
        </div>
        <div className="stock-list-details-container">
        <ul className="stock-list" style={{ backgroundColor: darkMode ? '#000000' : '#FFFFFF' }}>
          {stocks
        .filter(stock => !stock.symbol.startsWith('weekly_') && !stock.symbol.startsWith('monthly_'))
        .map((stock) => (
            <li
              key={stock.id}
              className={`stock-item ${selectedStock && selectedStock.id === stock.id ? "selected" : ""}`}
              onClick={() => handleSelect(stock)}
              onMouseEnter={(e) => e.currentTarget.style.backgroundColor = hoverStyle.backgroundColor}
              onMouseLeave={(e) => e.currentTarget.style.backgroundColor = ''}
              style={{ cursor: hoverStyle.cursor, borderBottom: selectedStock && selectedStock.id === stock.id ? '' : (darkMode ? '1px solid #2A2E39' : '1px solid #e2e2e2') }}
            >
              <div className="stock-symbol-column" style={{ color: darkMode ? '#FFFFFF' : '#000000' }}>
                {stock.symbol}
              </div>
              <div className="stock-column">
                {displayValue(stock.id, "close")}
              </div>
              <div className="stock-column">
                {displayValue(stock.id, "clschange")}
              </div>
              <div className="stock-column-with-line">
                {displayValue(stock.id, "pctclschange")}
              </div>
              <div className="stock-column">
                {displayValue(stock.id, "sentiment")}
              </div>
              <div className="stock-column-with-line">
                {displayValue(stock.id, "pctsentiment")}
              </div>
              <div className="stock-column">
                {displayValue(stock.id, "attention_buzz")}
              </div>
              <div className="stock-column">
                {displayValue(stock.id, "pctbuzz")}
              </div>
            </li>
    
          ))}
        </ul>
        </div>
        <div className="stock-list-container-background" style={{ backgroundColor: darkMode ? '#000000' : '#FFFFFF' }}>

        </div>
      </div>
    );
  }

  export default StockList;
