import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import axios from "axios";
import Highcharts from 'highcharts';
// Global Highcharts configurations
Highcharts.setOptions({
  global: {
    useUTC: false
  }
});
axios.defaults.baseURL = process.env.REACT_APP_BACKEND_BASE_URL;
// Override Highcharts Pointer reset function
Highcharts.Pointer.prototype.reset = function () {
  return undefined;
};
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
