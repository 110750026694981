import React, { useState } from "react";
import "./IndicatorButton.css";
import { IoBookOutline } from "react-icons/io5";

const IndicatorButton = ({ onClick, isActive, darkMode }) => {
  return (
    <button
      className={`indicator-button ${isActive ? 'active' : ''} ${darkMode ? 'dark-mode' : ''}`}
      title = "Indicators" 
      onClick={onClick} // No need to manage state within; it's passed down from the Home component
    >
      <IoBookOutline size={25} />
    </button>
  );
};

export default IndicatorButton;