import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './ConditionBuilder.css';

const ConditionBuilder = ({ darkMode}) => {

  return (
    <div className="condition-builder-container" style={{
      backgroundColor: darkMode ? '#000000' : '#FFFFFF', // White when darkMode is true, otherwise black
      }}>
      <div className = "condition-builder-header-container">
        <h1 >Condition Builder</h1>
        <div className = "condition-builder-main" style={{
          backgroundColor: darkMode ? '#000000' : '#FFFFFF', // White when darkMode is true, otherwise black
          }}>
          <div className="coming-soon-container" style={{
            backgroundColor: darkMode ? '#000000' : '#FFFFFF', // White when darkMode is true, otherwise black
            }}>
              <h2>Coming Soon</h2>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConditionBuilder;