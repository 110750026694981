import { useState } from "react";
import {
  Flex,
  Heading,
  Input,
  Button,
  InputGroup,
  Stack,
  InputLeftElement,
  chakra,
  Box,
  Link,
  Avatar,
  FormControl,
  FormHelperText,
  InputRightElement,
  Text,
  Image
} from "@chakra-ui/react";
// import { FaUserAlt, FaLock } from "react-icons/fa";
import loginBackground from '../assets/login_bg.gif';
import axios from "axios";
import { useNavigate } from "react-router-dom";
// import logo from "../assets/magneta-logo.png";
import logo from "../assets/l2.png";

// const CFaUserAlt = chakra(FaUserAlt);
// const CFaLock = chakra(FaLock);


const LoginInput = () => {
    const navigate = useNavigate();
    const [username, setUsername] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const handleUsernameChange = (event) => setUsername(event.target.value);

    const [password, setPassword] = useState('');
    const handlePasswordChange = (event) => setPassword(event.target.value);

    const [showPassword, setShowPassword] = useState(false);  
    const handleShowClick = () => setShowPassword((showPassword) => !showPassword);

    const handleLoginClick = (event) => {
      event.preventDefault();
      axios
        .post(`/api/auth/login/`, {
          username: username,
          password: password
        })
        .then((response) => {
          console.log("response from login: ", response);
          if (response.status === 200) {
            localStorage.setItem('token', response.data.token);
            navigate('/');
          }
        })
        .catch((error) => {
          console.error("Error logging in:", error);
          setErrorMessage("Invalid Username or Password");
      });
    }

    return (
      <Flex
        flex={1}
        flexDirection="column"
        width="100wh"
        height="100vh"
        backgroundImage={`url(${loginBackground})`}
        backgroundSize="cover" 
        justifyContent="center"
        alignItems="center"
      >
        <Stack
          flexDir="column"
          p="2"
          pb="4"
          // mb="2"
          justifyContent="center"
          alignItems="center"
          backgroundColor="white"
          borderRadius="xl"
          boxShadow="md"
          width="400px"
          height="420px"
        >
          <Stack
            flexDir="row"
          
            justifyContent="center"
            alignItems="center"
            // margin='4'
            padding="4"
            paddingTop="0"
            gap="0"
          >
            <Image 
                src={`${logo}`}
                alt="NLPify Logo"
                width="100px"
                margin="4"
                marginRight="0"
                marginLeft="0"
            />
            <Text
              fontFamily={"noto sans"}
              m="2" 
              // pr="2"
              // mt="4"
              pt="2"
              fontSize="20"
              fontWeight="600"
            >
              Welcome to NLPify <br/>Enterprise Platform
            </Text>
          </Stack>
          <Box w="300px" m="0">
            <form>
              <Stack
                spacing={4}
                // p="1rem"
                borderRadius="lg"
                // width="90%"
              >
                <FormControl>
                  <InputGroup>
                    {/* <InputLeftElement
                      pointerEvents="none"
                      children={<CFaUserAlt color="gray.300" />}
                    /> */}
                    <Input type="text" placeholder="Username" onChange={handleUsernameChange}/>
                  </InputGroup>
                </FormControl>
                <FormControl>
                  <InputGroup>
                    {/* <InputLeftElement
                      pointerEvents="none"
                      color="gray.300"
                      children={<CFaLock color="gray.300" />}
                    /> */}
                    <Input
                      type={showPassword ? "text" : "password"}
                      placeholder="Password"
                      onChange={handlePasswordChange}
                    />
                    <InputRightElement width="4.5rem">
                      {/* <Button h="1.75rem" size="sm" onClick={handleShowClick} borderRadius='0'>
                        {showPassword ? "Hide" : "Show"}
                      </Button> */}
                    </InputRightElement>
                  </InputGroup>
                  {/* <FormHelperText textAlign="right">
                    <Link>forgot password?</Link>
                  </FormHelperText> */}
                </FormControl>
                <Button
                  borderRadius='lg'
                  type="submit"
                  variant="solid"
                  // bg="#B100FF"
                  bg="black"
                  color="white"
                  _hover={{ bg: 'black' }}
                  // width="90%"
                  marginTop="5"
                  mb="2"
                  onClick={handleLoginClick}
                >
                  Login
                </Button>
                
              </Stack>
            </form>
            {errorMessage && (
              <Box  ml={"4em"}> {/* Adjust marginLeft (ml) as needed */}
                <Text color="red" fontSize="sm">
                  {errorMessage}
                </Text>
              </Box>
            )}  
          </Box>
          <Box >
          <Text fontSize="sm" sx={{ fontSize: "calc(1em)" }}>
  Not a user?{" "}
  <Link
   color="#b100ff"
   href="https://www.nlpify.ai/request-free-trial"
   sx={{
     textDecoration: 'none', // Ensure the link is not underlined by default
     _hover: {
       textDecoration: 'underline', // Apply underline on hover
     }
   }}
>
  Request a Free Trial
</Link>
</Text>
        </Box>
        </Stack>

      </Flex>
    );
  };
  
export default LoginInput;