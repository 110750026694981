import React, { useState } from "react";
import "./IndicatorButton.css";
import "./CrosshairButton.css"
import { IoBookOutline } from "react-icons/io5";
import { BiCross } from "react-icons/bi";

const CrosshairButton = ({ onClick, isActive, darkMode }) => {
    return (
      <button
        className={`crosshair-button ${isActive ? 'active' : ''} ${darkMode ? 'dark-mode' : ''}`}
        onClick={onClick} // Ensure this calls the passed onClick function
        title = "Crosshair" 
      >
        <BiCross size={22} />
      </button>
    );
  };
export default CrosshairButton;