import React from 'react';
import { FaRegMoon } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import './UserSettingsPanel.css';
import signoutButton from "../logout.svg"; // Import the SVG
import signoutButtonWhite from "../logout_white.svg";

const UserSettingsPanel = ({ darkMode, onToggleDarkMode }) => {
  const navigate = useNavigate();
  const handleSignOut = () => {
    // Clear localStorage
    localStorage.clear();
    
    // Clear sessionStorage
    sessionStorage.clear();
  
    // Add any other sign-out logic here, like API calls to invalidate the session
  
    // Navigate to login page
    navigate('/login');
  };

  return (
    <div className={`user-settings-panel ${darkMode ? 'dark-mode' : ''}`} style={{ backgroundColor: darkMode ? '#000000' : '#FFFFFF', color: darkMode ? '#FFFFFF' : 'inherit' }}>
      <div className="view-mode-row">
        <FaRegMoon size={30} />
        <div className="dark-mode-text" style={{color: darkMode ? '#FFFFFF' : '#000000' }}>Dark Mode</div>
        <label className="switch">
          <input type="checkbox" checked={darkMode} onChange={onToggleDarkMode} />
          <span className="slider round" style={{backgroundColor: darkMode ? '#8E55FA' : '#ccc' }}></span>
        </label>
      </div>
      <div className="view-mode-row">
        <div className="version-number-container" style={{color: darkMode ? '#FFFFFF' : '#000000' }}>
          NLPify Enterprise Platform v0.1.0
        </div>
      </div>
      <div className="user-option-separator"></div>
      <div className={`view-mode-row sign-out-row ${darkMode ? 'dark-mode' : ''}`} onClick={handleSignOut}>
        {/* Inline styles for setting the size */}
        <img src={darkMode ? signoutButtonWhite : signoutButton} alt="Sign Out" style={{ width: '20px', height: '20px', marginRight: '10px', marginTop:'10px' }}/>
        <div className="sign-out-text" style={{color: darkMode ? '#FFFFFF' : '#000000' }}>Sign Out</div>
      </div>
    </div>
  );
};

export default UserSettingsPanel;