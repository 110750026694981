import React, { useState, useRef, useEffect} from 'react';
import './ResolutionSelector.css';

const ResolutionSelector = ({ onSelectionChange, darkMode }) => {
  const [isOpen, setIsOpen] = useState(false);
  // Keep the selected option's first letter as the state
  const [selectedOption, setSelectedOption] = useState('D'); 

  const dropdownRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsOpen(false);
            }
        };

        if (isOpen) {
            document.addEventListener('mousedown', handleClickOutside);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isOpen]);
  const handleButtonClick = () => setIsOpen(!isOpen);

  const handleOptionSelect = (option) => {
    onSelectionChange(option);
    setSelectedOption(option.charAt(0));
    setIsOpen(false);
  };

  // Helper function to determine the appropriate class
  const getOptionClassName = (option) => {
    return option.charAt(0) === selectedOption ? 'option-selected' : '';
  };

  return (
    <div ref={dropdownRef} className={`resolution-select-container ${darkMode ? 'dark-mode' : ''}`}>
      <button onClick={handleButtonClick} className={`resolution-button ${isOpen ? 'active' : ''}`}>
        {selectedOption}
      </button>
      {isOpen && (
        <div className="resolution-options" style={{
          boxShadow: darkMode ? "0px 0px 4px #2a2e39" : "0px 0px 8px rgba(0, 0, 0, 0.1)",
          }}>
          <div className={`option ${getOptionClassName('Daily')}`} onClick={() => handleOptionSelect('Daily')}>Daily</div>
          <div className={`option ${getOptionClassName('Weekly')}`} onClick={() => handleOptionSelect('Weekly')}>Weekly</div>
          <div className={`option ${getOptionClassName('Monthly')}`} onClick={() => handleOptionSelect('Monthly')}>Monthly</div>
        </div>
      )}
    </div>
  );
};


export default ResolutionSelector;